/* Common colors */
@whiteColor: white;
@whiteColor70: rgba(255, 255, 255, 0.7);
@pageBgColor: var(--color-gray-75);
@black2Color: rgba(0, 0, 0, 0.02);
@black3Color: rgba(0, 0, 0, 0.03);
@black5Color: rgba(0, 0, 0, 0.05);
@black30Color: rgba(0, 0, 0, 0.30);
@black5ColorSolid: #EDEFF3;
@black10Color: rgba(0, 0, 0, 0.1);
@borderColor: var(--color-gray-300);
@borderColorFocus: var(--color-gray-400);
@grayAColor: rgba(172, 176, 198, 1); /* file history points */
@grayAColor3: rgba(172, 176, 198, 0.3);
@greenLightColor: #80C49B;
@inactiveTabTextColor: @black30Color;
@tabsComponentBgColor: #FFFFFF;
@partbTabsBg: #f5f5f5;
@partbTabActiveTextColor: #202124;
@partbDisabledFieldBgColor: #EFEFEF;

/* Text */
@darkTextColor: var(--color-gray-800);
@darkTextColor2: rgba(27, 35, 49, 0.02);
@darkTextColor3: rgba(27, 35, 49, 0.03);
@darkTextColor5: rgba(27, 35, 49, 0.05);
@darkTextColor30: rgba(27, 35, 49, 0.3);
@darkTextColor40: rgba(27, 35, 49, 0.4);
@darkTextColor50: rgba(27, 35, 49, 0.5);
@darkTextColor60: rgba(27, 35, 49, 0.6);
@darkTextColor70: rgba(27, 35, 49, 0.7);
@darkTextColor80: rgba(27, 35, 49, 0.8);

/* Notice colors */
@infoNotice: #4CAAE5;
@infoNoticeRGB: 76, 170, 229;
@infoNotice5: rgba(76, 170, 229, 0.05);
@infoNotice15Solid: #E4F2FB;
@infoNotice20: rgba(76, 170, 229, 0.2);
@successNotice: #10B981;
@successNoticeRGB: 16, 185, 129;
@successNotice5: rgba(16, 185, 129, 0.05);
@successNotice15Solid: #DBF5EC;
@warningNotice: #FFA385;
@warningNoticeRGB: 255, 163, 133;
@warningNotice5: rgba(255, 163, 133, 0.05);
@warningNotice15Solid: #FFF1ED;
@errorNotice: #F75F5F;
@errorNoticeRGB: 247, 95, 95;
@errorNotice5: rgba(247, 95, 95, 0.05);
@errorNotice5Solid: #fff7f7;
@errorNotice15Solid: #FEE7E7;

/* Formio 3 Notifications */
@successNotification: #1C8C6E;

/* Fields feedback */
@successColor: rgba(41, 188, 143, 1);
@successColor3: rgba(41, 188, 143, 0.3);
@successColor60: rgba(41, 188, 143, 0.6);
@errorColor: rgba(255, 0, 59, 1);
@errorColor30: rgba(255, 0, 59, 0.3);
@errorColor50: rgba(255, 0, 59, 0.5);
@errorColor70: rgba(255, 0, 59, 0.7);
@errorColor60: rgba(255, 0, 59, 0.6);

/* Document status */
@documentExpired: rgba(232, 79, 115, 1);
@documentExpiring: rgba(232, 79, 115, 0.5);

/* Theme colors*/
/* Blue color */
@accentBlue: rgba(70, 120, 170, 1);
@accentBlue70: rgba(70, 120, 170, 0.7);
@accentBlue50: rgba(70, 120, 170, 0.5);
@accentBlue40: rgba(70, 120, 170, 0.4);
@accentBlue30: rgba(70, 120, 170, 0.3);
@accentBlue20: rgba(70, 120, 170, 0.2);
@accentBlue10: rgba(70, 120, 170, 0.1);
@accentBlue10Solid: #ECF1F6;
@accentBlue5: rgba(70, 120, 170, 0.05);
@accentBlue5Solid: #F6F8FB;
@accentBlue3: rgba(70, 120, 170, 0.03);

/* Dark blue color */
@accentDarkBlue: rgba(28, 49, 87, 1);
@accentDarkBlue70: rgba(28, 49, 87, 0.7);
@accentDarkBlue50: rgba(28, 49, 87, 0.5);
@accentDarkBlue40: rgba(28, 49, 87, 0.4);
@accentDarkBlue30: rgba(28, 49, 87, 0.3);
@accentDarkBlue20: rgba(28, 49, 87, 0.2);
@accentDarkBlue10: rgba(28, 49, 87, 0.1);
@accentDarkBlue10Solid: #E8EAEE;
@accentDarkBlue5: rgba(28, 49, 87, 0.05);
@accentDarkBlue5Solid: #F3F4F6;
@accentDarkBlue3: rgba(28, 49, 87, 0.1);

/* Gray color */
@accentGray: rgba(74, 98, 136, 1);
@accentGray80: rgba(74, 98, 136, 0.8);
@accentGray70: rgba(74, 98, 136, 0.7);
@accentGray70Solid: #8091AB;
@accentGray50: rgba(74, 98, 136, 0.5);
@accentGray40: rgba(74, 98, 136, 0.4);
@accentGray30: rgba(74, 98, 136, 0.3);
@accentGray20: rgba(74, 98, 136, 0.2);
@accentGray10: rgba(74, 98, 136, 0.1);
@accentGray10Solid: #EDEFF3;
@accentGray5: rgba(74, 98, 136, 0.05);
@accentGray5Solid: #F6F7F9;
@accentGray3: rgba(74, 98, 136, 0.03);

/* Violet color */
@accentViolet: rgba(70, 81, 170, 1);
@accentViolet80: rgba(70, 81, 170, 0.8);
@accentViolet70: rgba(70, 81, 170, 0.7);
@accentViolet70Solid: #7E86C4;
@accentViolet50: rgba(70, 81, 170, 0.5);
@accentViolet40: rgba(70, 81, 170, 0.4);
@accentViolet30: rgba(70, 81, 170, 0.3);
@accentViolet20: rgba(70, 81, 170, 0.2);
@accentViolet10: rgba(70, 81, 170, 0.1);
@accentViolet075: rgba(70, 81, 170, 0.075);
@accentViolet10Solid: #EDEEF7;
@accentViolet5: rgba(70, 81, 170, 0.05);
@accentViolet5Solid: #F5F6FA;
@accentViolet3: rgba(70, 81, 170, 0.03);
@violetHEX: #4651aa;

/* Theme color vars */
@accentColor: @accentViolet;
@accentColor80: @accentViolet80;
@accentColor70: @accentViolet70;
@accentColor70Solid: @accentViolet70Solid;
@accentColor50: @accentViolet50;
@accentColor40: @accentViolet40;
@accentColor30: @accentViolet30;
@accentColor20: @accentViolet20;
@accentColor10: @accentViolet10;
@accentColor075: @accentViolet075;
@accentColor10Solid: @accentViolet10Solid;
@accentColor5: @accentViolet5;
@accentColor5Solid: @accentViolet5Solid;
@accentColor3: @accentViolet3;
@accentHEX: @violetHEX;

@escHexAccentColor: escape(@accentHEX);

.violet-color {
  color: rgba(70, 81, 170, 1);
}
.green-color {
  color: rgba(56, 159, 143, 1);
}
.pink-color {
  color: rgba(147, 80, 116, 1);
}

// Celia's revamp for Libya
@dashboarNotifBg: #eaebeb;
@darkPrimary: #1B2231;
@labelColor: #60656F;