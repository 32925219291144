/* Theme vars */
@roundedCorners: 6px;
@panelPaddingMobile: 25px;
@panelPadding: 24px;
@panelBodyPadding: 24px;
@fieldHeight: 40px;
@defaultTextSize: 16px;
@guidePanelHeadingFontSize: 20px;
@panelHeadingFontSize: 20px;
@subPanelHeadingFontSize: 18px;
@miniPanelHeadingFontSize: 20px;
@panelHeadingBottomPadding: 10px;
@panelHeadingPaddingY: 24px;
@miniPanelYPadding: 20px;

@panelHeadingBottomBorderColor: var(--color-gray-75);
@subBlockLineColor: var(--color-gray-75);
@componentSeparation: 30px;

/* Font */
// Open Sans 400 500 600 700 800
@font-face {
  font-family: 'Open Sans';
  font-weight: 800;
  src: local('Open Sans ExtraBold'),
       url('/assets/fonts/opensans/opensans-extrabold-webfont.woff2') format('woff2'),
       url('/assets/fonts/opensans/opensans-extrabold-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  src: local('Open Sans Bold'),
       url('/assets/fonts/opensans/opensans-bold-webfont.woff2') format('woff2'),
       url('/assets/fonts/opensans/opensans-bold-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  src: local('Open Sans SemiBold'),
       url('/assets/fonts/opensans/opensans-semibold-webfont.woff2') format('woff2'),
       url('/assets/fonts/opensans/opensans-semibold-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 500;
  src: local('Open Sans Medium'),
       url('/assets/fonts/opensans/opensans-medium-webfont.woff2') format('woff2'),
       url('/assets/fonts/opensans/opensans-medium-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  src: local('Open Sans'),
       url('/assets/fonts/opensans/opensans-regular-webfont.woff2') format('woff2'),
       url('/assets/fonts/opensans/opensans-regular-webfont.woff') format('woff');
}

// ANCHOR Inter
@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/inter/inter.woff2') format('woff2');
}

/* ANCHOR Colors */
@import "../default/_themes-colors.less";

/* ANCHOR Theme partials */
@import "./_service-bar.less";
@import "./_dashboard.less";
@import "./_application-steps.less";
@import "./_form-components.less";
@import "./_part-b.less";
@import "./_inspector.less";
@import "./_finished-service.less";
@import "./_business-list.less";

/* ANCHOR Common */
body {
  &.modern-theme {
    background-color: @pageBgColor;
    font-family: var(--main-font-family, 'Open Sans', sans-serif);
    padding-inline-end: 0;
    padding-right: initial !important;

    h1, h2, h3, h4, h5 {
      font-family: var(--main-font-family, 'Open Sans', sans-serif);
    }

    .border-none {
      border: none;
    }
  }
}

// ANCHOR Custom properties
:root {
  // ANCHOR Fonts
  --main-font-family: 'Open Sans', sans-serif;
  --fontawesome-font: 'Font Awesome 6 Pro';

  // Colors
  // ANCHOR Primary
  --color-primary-50: #EDEEF7;
  --color-primary-75: #E3E4F2;
  --color-primary-100: #C6C9E5;
  --color-primary-200: #AAAFD8;
  --color-primary-300: #8D94CB;
  --color-primary-400: #7179BE;
  --color-primary-500: #545EB0;
  --color-primary-600: #4651AA;
  --color-primary-700: #363E83;
  --color-primary-800: #2B3269;
  --color-primary-900: #20254F;
  --color-primary-1000: #161934;
  --color-primary-1100: #0B0C1A;
  // ANCHOR Gray
  --color-gray-50: #F9FAFB;
  --color-gray-75: #F6F7F8;
  --color-gray-100: #EDEEF1;
  --color-gray-200: #E5E7EB;
  --color-gray-300: #D1D5DB;
  --color-gray-400: #9CA3AF;
  --color-gray-500: #6B7280;
  --color-gray-600: #4B5563;
  --color-gray-700: #374151;
  --color-gray-800: #1F2937;
  --color-gray-900: #111827;
  // ANCHOR Blue
  --color-blue-50: hsla(229, 85%, 97%, 1);
  --color-blue-100: hsla(227, 85%, 95%, 1);
  --color-blue-200: hsla(224, 86%, 89%, 1);
  --color-blue-300: hsla(224, 89%, 82%, 1);
  --color-blue-400: hsla(222, 90%, 73%, 1);
  --color-blue-500: hsla(217, 91%, 60%, 1);
  --color-blue-600: hsla(217, 70%, 54%, 1);
  --color-blue-700: hsla(217, 61%, 46%, 1);
  --color-blue-800: hsla(217, 62%, 38%, 1);
  --color-blue-900: hsla(217, 62%, 27%, 1);
  // ANCHOR Orange
  --color-orange-50: hsla(14, 100%, 97%, 1);
  --color-orange-100: hsla(15, 100%, 94%, 1);
  --color-orange-200: hsla(10, 100%, 92%, 1);
  --color-orange-300: hsla(15, 100%, 76%, 1);
  --color-orange-400: hsla(19, 100%, 65%, 1);
  --color-orange-500: hsla(19, 100%, 60%, 1);
  --color-orange-600: hsla(18, 74%, 53%, 1);
  --color-orange-700: hsla(18, 67%, 47%, 1);
  --color-orange-800: hsla(18, 66%, 41%, 1);
  --color-orange-900: hsla(18, 67%, 33%, 1);
  // ANCHOR Text Color
  --main-text-color: var(--color-gray-800);
  // ANCHOR Other
  --color-white: hsla(0, 0%, 100%);
  --color-black: hsla(0, 0%, 0%);
  --color-trash: hsla(0deg, 90%, 67%, 1);
  --color-trash-hover: hsla(0deg, 100%, 57%, 1);
  // !Colors

  // Wizard
  --wizard-page-menu-height: 68px;
  --wizard-page-menu-top-margin: 0px;
  --wizard-page-menu-bottom-margin: 36px;
  --wizard-bar-bg-color: var(--color-white);
  --wizard-page-link-text-color: var(--color-gray-400);
  --wizard-page-link-active-text-color: var(--color-white);
  --wizard-page-link-bg-color: transparent;
  --wizard-page-link-active-bg-color: var(--color-gray-900);
  --wizard-page-link-text-size: 20px;
  --wizard-page-link-text-weight: 600;
  --wizard-page-link-block-padding: 8px;
  --wizard-page-link-inline-padding: 24px;
  --wizard-page-link-border-radius: 40px;
  --wizard-bar-items-gap: 12px;
  // !Wizard
  
  // ANCHOR Buttons
  --button-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  --button-shadow-hover: 0 3px 4px rgba(0, 0, 0, 0.20);
  
  // ANCHOR Part B
  --part-b-services-menu-bg-color: var(--color-gray-200);
  --part-b-roles-menu-bg-color: var(--color-gray-50);
  
  // ANCHOR other
  --menu-bar-page-name-size: 22px;
  --page-wrapper-header-width: 95%;
  --page-wrapper-menu-width: 95%;
  --page-wrapper-content-width: calc(95% + 4.8rem);
  --page-wrapper-wizard-width: 100%;
  --page-wrapper-form-width: calc(95% + 4.8rem);
  --colored-bg-content-padding-x: 24px;

  // ANCHOR Editgrid
  --eg-add-edit-entry-row-bgcolor: var(--color-white);
  --eg-add-edit-entry-row-shadow: 0 5px 15px #00000010;

  // ANCHOR Button sizes
  // ANCHOR Height
  --xs-button-height: 30px;
  --sm-button-height: 36px;
  --md-button-height: 40px;
  --lg-button-height: 48px;
  --xl-button-height: 60px;
  // ANCHOR Padding
  --xs-button-inline-padding: 16px;
  --xs-button-block-padding: 8px;
  --sm-button-inline-padding: 20px;
  --sm-button-block-padding: 10px;
  --md-button-inline-padding: 24px;
  --md-button-block-padding: 10px;
  --lg-button-inline-padding: 32px;
  --lg-button-block-padding: 12px;
  --xl-button-inline-padding: 40px;
  --xl-button-block-padding: 16px;
  // ANCHOR Font size
  --xs-button-font-size: 14px;
  --sm-button-font-size: 14px;
  --md-button-font-size: 16px;
  --lg-button-font-size: 18px;
  --xl-button-font-size: 20px;
  // ANCHOR Border radius
  --xs-button-border-radius: 4px;
  --sm-button-border-radius: 4px;
  --md-button-border-radius: 6px;
  --lg-button-border-radius: 6px;
  --xl-button-border-radius: 8px;
  // ANCHOR Default
  --default-button-height: var(--md-button-height);
  --default-button-font-size: var(--md-button-font-size);
  --default-button-inline-padding: var(--md-button-inline-padding);
  --default-button-block-padding: var(--md-button-block-padding);
  --default-button-border-radius: var(--md-button-border-radius);
}