/* Theme color vars */
@accentColor: rgba(33, 112, 152, 1);
@accentColor80: rgba(33, 112, 152, 0.8);
@accentColor70: rgba(33, 112, 152, 0.7);
@accentColor70Solid: #649BB7;
@accentColor50: rgba(33, 112, 152, 0.5);
@accentColor40: rgba(33, 112, 152, 0.4);
@accentColor30: rgba(33, 112, 152, 0.3);
@accentColor20: rgba(33, 112, 152, 0.2);
@accentColor10: rgba(33, 112, 152, 0.1);
@accentColor075: rgba(33, 112, 152, 0.075);
@accentColor10Solid: #E9F1F5;
@accentColor5: rgba(33, 112, 152, 0.05);
@accentColor5Solid: #F4F8FA;
@accentColor3: rgba(33, 112, 152, 0.03);
@accentHEX: #217098;

@escHexAccentColor: escape(@accentHEX);

:root {
  // Colors
  // Primary
  --color-primary-50: #E9F1F5;
  --color-primary-75: #DDE9EF;
  --color-primary-100: #BBD3DF;
  --color-primary-200: #98BDCF;
  --color-primary-300: #76A7C0;
  --color-primary-400: #5491B0;
  --color-primary-500: #327BA0;
  --color-primary-600: #217098;
  --color-primary-700: #195675;
  --color-primary-800: #14455E;
  --color-primary-900: #0F3446;
  --color-primary-1000: #0A222F;
  --color-primary-1100: #051117;
}