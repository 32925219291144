@import '~font-awesome/css/all.css';

input {
    &.form-control {
        &:-webkit-autofill {
            background: transparent !important;
            background-color: transparent !important;
            background-image: none !important;
        }    
    }
    &:-webkit-autofill {
        background: transparent !important;
        background-color: transparent !important;
        background-image: none !important;
    }
}
.dashboard {
    > .form-group.form-field-type-panel {
        > .panel {
            border-inline-start: none;

            .panel-heading {
                padding-top: 2.5rem;
            }
            .panel-body {
                padding-bottom: 1.9rem;
            }
        }
    }
}
.glyphicon-question-sign {
    &:before {
        content: "\F059";
        font-family: var(--fontawesome-font);
        font-weight: 700;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        color: @grey;
        font-size: 1.6rem;
        margin: 0 .5rem;
    }
}
.ui-select-offscreen,
.form-field-type-hidden {
    display: none;
}
.checkbox {
    margin: 0;
    line-height: 1;

    > label {
        padding: 0;
        min-height: auto;
        margin-bottom: 0 !important;
    }
    input[type="checkbox"] {
        &:not(:last-child) {
            position: absolute;
        }
        & + :where(label, span) {
            padding-inline-start: 2rem;
            position: relative;
            display: inline;
            font-size: 14px;
            line-height: 14px;

            &:before {
                position: absolute;
                inset-inline-start: 0;
                top: 0px;
                content: "\f0c8";
                font-family: var(--fontawesome-font);
                font-weight: 300;
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 14px;
                color: @dark;
                font-size: 16px;
                border: none;
            }
        }
        &:checked {
            + label:before, + span:before {
                content: "\f14a";
                transform: none;
            }
        }
        &.ng-pristine {
            &.ng-valid:not(.ng-empty) {
                + label, + span {
                    color: @dark;

                    &:before {
                        color: @success;
                        font-weight: 600;
                    }
                }
            }
        }
        &:disabled {
            + :where(label, span) {
                cursor: not-allowed;

                &:before {
                    cursor: not-allowed;
                }
            }
        }
    }
}
:where(.radio-inline > .label-control, .radio-inline label) {
    margin: 0;

    input[type="radio"] {
        margin-top: 0;

        & + span {
            font-weight: 400;
        }
    }
}
.form-field-type-file {
    .fileSelector {
        padding: 2rem;
        background-color: @main-bg;
        text-align: center;
        color: @dark;
        margin-bottom: 2rem;

        > a {
            text-decoration: underline;
        }
        .glyphicon {
            margin-inline-end: 1rem;
            font-size: 1.8rem;
            color: @grey;
        }
    }
    .glyphicon-asterisk {
        display: none;
    }
}
.has-success {
    .field-required {
        &:after {
            content: "\f00c";
            font-family: var(--fontawesome-font);
            font-weight: 300;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            color: @success;
            font-size: 1.25rem;
            border: none;
        }
    }
    .ui-select-match {
        > .btn-default {
            border-color: @success;
        }
    }
    :where(label, .control-label, .form-control-feedback) {
        color: @dark;
    }
    .checkbox {
        input[type="checkbox"]:checked {
            + :where(label, span) {
                &:before {
                    font-weight: 600;
                    color: @success;
                }
            }
        }
    }
}
.has-error {
    .field-required {
        &:after {
            content: "\f05e";
            font-family: var(--fontawesome-font);
            font-weight: 300;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            color: @danger;
            font-size: 1.25rem;
            border: none;
        }
    }
    .ui-select-match {
        > .btn-default {
            border-color: @danger;
        }
    }
    :where(label, .control-label, .help-block, .form-control-feedback) {
        color: @dark;
    }
    .checkbox {
        input[type="checkbox"] {
            + :where(label, span) {
                &:before {
                    font-weight: 600;
                    color: @danger;
                }
            }
        }
    }
}
.panel {
    box-shadow: 0 1px 3px rgba(0,0,0,0.16);
    border-radius: 0;
    margin-bottom: 0;
    padding: 0;
    background-color: @basegrey;

    .panel-heading {
        background-color: transparent;
        border-radius: 0;
        border: none;
        padding: 2.5rem 2.5rem 0;

        h3 {
            &.panel-title {
                color: @dark;
                font-size: 2rem;
                font-weight: 500;
                border: none;
                position: relative;
                padding: 0 0 1rem;
            }
            .item-count {
                font-size: 1.6rem;
                color: @grey;
            }
        }
    }
}
:where(.formio-component-yourPaymentIsConfirmed, blockquote) {
    line-height: 1.4 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    padding: 1.5rem 3rem !important;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    margin-inline: -3rem;
    position: relative !important;
    color: black !important;
    border: none !important;
    background: @greylines !important;

    &:after {
        content: " " !important;
        height: 100% !important;
        top: 0 !important;
        width: 3px !important;
        inset-inline-start: 0 !important;
        position: absolute !important;
        background: @blue !important;
        display: block !important;
    }
    a {
        color: @primary;
    }
    :where(article, p) {
        &:last-child {
            margin: 0 !important;
        }
    }
    &.alert-danger {
        &:after {
            background: @danger !important;
        }
    }
    &:where(.formio-component-yourPaymentIsConfirmed, .alert-success) {
        &:after {
            background: @success !important;
        }
    }
    &.alert-warning {
        &:after {
            background: @warning !important;
        }
    }
}
.formio-component-yourPaymentIsConfirmed {
    * {
        font-family: "Open Sans", sans-serif, Helvetica, Arial, sans-serif;
        color: fade(@dark, 50%) !important;
        line-height: 1.4 !important;
        font-size: 13px !important;
        font-weight: 400 !important;

        &.glyphicon {
            &:before {
                display: none !important;
            }
        }
    }
}
.form-field-type-mycard {
    .card {
        .card-body {
            margin: 0 !important;
        }
    }
}
.formio-component-columnsPrint {
    button&,
    button {
        border-radius: 2px !important;
        background-color: @grey;
        border-color: @grey;
        font-size: 14px;
        box-shadow: 0 1px 3px rgba(0,0,0,0.16) !important;
        width: 100%;
        padding: 0.8rem 1.5rem;
        transition: all .3s;
        color: @white;
        line-height: 1;

        &:where(:active, :focus, :hover) {
            border-color: darken(@grey, 10%);
            background-color: darken(@grey, 10%);
            color: @white;
        }
    }
    button& {
        width: auto !important;
    }
}
.print-button {
    button {
        width: auto;
        padding: 0.8rem 1.5rem;
    }
    &.formio-component-printMyForm {
        text-align: start;
    }
}
:where(.formio-component-btnValidateEnabled, .formio-component-columnsValidateandcontinue) {
    margin-bottom: 0 !important;

    :where(a, button) {
        color: @white;
        text-decoration: none;
        border-radius: 2px;
        background-color: @blue;
        border-color: @blue;
        font-size: 16px;
        font-weight: 500;
        min-width: fit-content;
        padding: 1.5rem 1rem;
        display: inline-flex;
        text-align: center;
        transition: all .3s;
        line-height: 1;

        &:where(:active, :focus, :hover) {
            background-color: darken(@blue, 5%);
            color: @white;
        }
        &.disabled {
            color: @white !important;
            background-color: fade(@blue,50%);
        }
    }
}
.formio-component-columnsValidateandcontinue {
    button {
        justify-content: center;
    }
}
.formio-component-btnSwornSave {
    margin-top: 1rem;
    margin-bottom: 0;

    button {
        color: @white;
        text-decoration: none;
        border-radius: 2px !important;
        background-color: @success;
        border-color: @success;
        font-size: 1.6rem;
        font-weight: 500;
        text-transform: uppercase;
        box-shadow: 0 1px 3px rgba(0,0,0,0.16) !important;
        width: auto;
        padding: .5rem 1rem;
        display: block;
        text-align: center;
        transition: all .3s;
        line-height: 1;

        &:where(:active, :focus, :hover) {
            border-color: @success;
            background-color: darken(@success, 5%);
            color: @white;
        }
        &.disabled {
            color: @white !important;
            background-color: fade(@success,50%);
        }
    }
}
.formio-data-grid {
    > .datagrid-table {
        margin: 0;

        + .datagrid-add {
            margin: 0 3rem !important;
        }
    }
    > .panel {
        box-shadow: none;

        .panel-body {
            padding: 0 3rem;

            .datagrid-add {
                margin: 1.5rem 0;
            }
        }
    }
    .list-group {
        overflow-x: auto;
        background-color: white;
        border-radius: 3px;
        border: solid 1px #D5D6D9;
        width: fit-content;
        max-width: 100%;

        &:empty {
          margin: 0;
        }
        .list-group-item {
            width: fit-content;
            display: block;
            margin-bottom: 0;
            background-color: white;
            border: none;
            border-top: 1px solid fade(@dark,15%);
            padding: 0 2.5rem 0 2.5rem;
            line-height: 1;
            text-align: start;
            font-size: 15px;
            border-radius: 3px 3px 0 0 !important;

            &.list-group-header {
                font-weight: 500;
                font-size: 12px;
                padding: 0 2.5rem 0 2.5rem;
                letter-spacing: 0.5px;
                background: #eff0f1;
                border-top: none;
                border-bottom: 1px solid fade(@dark,15%);

                .row {
                    display: grid;
                    grid-auto-columns: 200px;
                    grid-auto-flow: column;
                    grid-template-columns: repeat(auto-fit, 200px) 70px !important;
                    min-width: min-content;
                    max-width: max-content;

                    > div {
                        width: auto;
                        word-break: break-word;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border-inline-end: solid 1px #D5D6D9;

                        &:last-of-type {
                            position: sticky;
                            inset-inline-end: 0;
                            background-color: #eff0f1;
                            height: 100%;
                            max-width: 100px;
                            min-width: 100px;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            justify-self: flex-end;
                            border-inline-end: none;
                        }
                        &:empty {
                            display: none;
                        }
                    }
                    &::after,
                    &::before {
                        display: none !important;
                    }
                    > div:blank {
                        display: none;
                    }                    
                }

                & + .list-group-footer {
                    display: none;
                }
                & + .list-group-item {
                    border-top: none;
                    box-shadow: 0 1px 0 #e3e3e5;
                }
            }
            &:not(.list-group-header) {
                > div {
                    > edit-grid-row {
                        > div {
                            > render-template {
                                > .row {
                                    display: grid;
                                    grid-auto-columns: 200px;
                                    grid-auto-flow: column;
                                    grid-template-columns: repeat(auto-fit, 200px) 70px;
                                    min-width: min-content;
                                    max-width: max-content;

                                    > div {
                                        width: auto;
                                        word-break: break-word;
                                        padding-top: 8px;
                                        padding-bottom: 8px;
                                        line-height: 1.2;

                                        &:last-of-type {
                                            position: sticky;
                                            inset-inline-end: 0;
                                            background-color: white;
                                            height: 100%;
                                            max-width: 100px;
                                            min-width: 100px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: flex-end;
                                            justify-self: flex-end;
                                        }
                                        &:empty {
                                            display: none;
                                        }
                                    }
                                    &::after,
                                    &::before {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
                &:last-of-type {
                    border-bottom: none;
                }
            }
            &.list-group-footer {
                .row {
                    display: grid;
                    grid-auto-columns: 200px;
                    grid-auto-flow: column;
                    grid-template-columns: repeat(auto-fit, 200px) 70px !important;
                    min-width: min-content;
                    max-width: max-content;

                    > div {
                        width: auto;
                        word-break: break-word;
                        padding-top: 8px;
                        padding-bottom: 8px;
                    }

                    &::after,
                    &::before {
                        display: none;
                    }
                }
            }
            &:hover {
                .btn-icon-toggle {
                    opacity: 1;
                }
            }
            .edit-body {
                margin-bottom: 0;
                padding-bottom: 1.5rem;
                margin-top: 0;
                padding-top: 1.5rem;
            }
            .editgrid-body {
                padding: 1.5rem 3rem;
                margin: -1.5rem -3rem;

                .form-group {
                    &.form-field-type-columns {
                        > .row {
                            align-items: baseline;

                            > div {
                                flex: 1 100%;
                            }
                        }
                    }
                }
            }
        }
        .btn-icon-toggle {
            width: auto;
            padding-inline-start: 0.5rem;
            padding-inline-end: 0.5rem;
            opacity: 0;
            transition: all .3s;
            color: @grey;

            &:where(:active, :focus, :hover) {
                background: transparent;
                color: @dark;
            }
        }
    }
    .datagrid-add {
        margin: 0.5rem 0rem 2rem;

        a {
            border: 1px solid @blue;
            color: @blue;
            background-color: transparent;
            border-radius: 2px !important;
            font-weight: 400;
            padding: .5rem 1rem;
            line-height: 1;

            .glyphicon {
                display: none;
            }
            &:where(:active, :focus, :hover) {
                border-color: darken(@blue, 5%);
                color: darken(@blue, 5%);
                background-color: @white;
            }
        }
    }
}
@media (max-width: 768px) {
    .form-field-type-columns {
        >.row {
            flex-direction: column;
            position: relative;
            >div {
                +div {
                    margin-top: 2rem;
                }
            }
        }
    }
}

/* Inspector */
#entreprise-grid {
    #services-select {
        width: initial;
        max-width: 240px;
    }
}
// Materialadmin override
.checkbox-styled:not(ie8) input ~ span:before,
.radio-styled:not(ie8) input ~ span:before {
    border-color: @grey;
    border-width: 1px;
}
.checkbox-styled:not(ie8).checkbox-info input:checked ~ span:before,
.radio-styled:not(ie8).checkbox-info input:checked ~ span:before,
.checkbox-styled:not(ie8).radio-info input:checked ~ span:before,
.radio-styled:not(ie8).radio-info input:checked ~ span:before {
    border-color: @blue;
    background: @blue;
}
.checkbox-styled:not(ie8).checkbox-info input ~ span:after,
.radio-styled:not(ie8).checkbox-info input ~ span:after,
.checkbox-styled:not(ie8).radio-info input ~ span:after,
.radio-styled:not(ie8).radio-info input ~ span:after {
    background-color: @white;
}
.radio-styled:not(ie8) input:checked ~ span:after {
    inset-inline-start: 5px;
    top: 10px;
    width: 6px;
    height: 6px;
}
.formio-component-swearColumns {
    > div {
        > div {
            &:first-child {
                flex: 0 !important;
            }
            .formio-component-swearCheckbox {
                padding-top: .2rem;
            }
        }
    }
}
.swear-content {
    font-size: 1.8rem !important;
}
.ruler-xs {
    hr {
        margin-top: 0.5rem !important;
        margin-bottom: 1.5rem !important;
        margin-inline: 0 !important;
    }
}
.form-field-type-htmlelement {
    hr {
        margin: 1.5rem 0;
    }
}

input[disabled],
input[disabled] ~ .input-group-addon,
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    cursor: not-allowed;
    color: @dark;
    opacity: 1;
    background: @main-bg;
    text-align: center;
}
input[disabled] ~ .input-group-addon {
    color: #9AA7B7 !important;
}
input[disabled] {
    text-align: start;
}
.ui-checkbox {
    position: relative;
    margin: 0 20px;

    &.ui-checkbox-nolabel {
        margin: 0;

        input[type="checkbox"] {
            & + span {
                &:before {
                    margin: 0;
                }
            }
        }
    }
    input[type="checkbox"] {
        display: none;

        & + span {
            font-weight: normal;

            &:hover {
                cursor: pointer;
            }
            &:before {
                content: "\f205";
                font-family: "Font Awesome 5 Pro";
                font-weight: 900;
                display: inline-block;
                vertical-align: middle;
                margin-inline-end: 10px;
                margin-inline-start: -20px;
                color: @grey;
                font-size: 2rem;
                transform: scale(-1, 1);
            }
        }
        &:disabled {
            & + span {
                &:before {
                    opacity: .65;
                    cursor: no-drop;
                }
                &:hover {
                    cursor: not-allowed;
                }
            }
        }
        &:checked {
            & + span {
                &:before {
                    content: "\f205";
                    font-family: "Font Awesome 5 Pro";
                    transform: initial;
                    font-weight: 900;
                    color: @success;
                }
            }
        }
    }
}
.editable-page-title {
    i {
        opacity: 0.25;
        transition: all .3s;
        font-size: 1.325rem;
        vertical-align: middle;
        margin-top: -2px;
    }
    &:hover {
        i {
            opacity: 1;
            transition: all .3s;
        }
    }
}
.editable-page-title-edit-zone {
    position: relative;

    input {
        border: none;
        padding: 0 48px 0 0;
        width: 100%;
        background-color: transparent;
        border-bottom: 1px dotted @primary;

        &:focus {
            outline: none;
        }
    }
    i {
        position: absolute;
        top: 8px;
        font-size: 1.325rem;

        &.fa-times {
            inset-inline-end: 24px;
        }
        &.fa-check {
            inset-inline-end: 4px;
        }
    }
    span[form="form"] {
        a.fancy.img-thumbnail {
            width: auto;
            height: auto;
        }
        & + span {
            position: relative;
            top: -2.7rem;
        }
    }
}
:where(.formio-component-costtable, .payment-table-form) {
    p {
        margin: 0 !important;
    }
    hr {
        border-top-color: @main-bg !important;
    }
    :where(.form-field-type-mycontent, .card-body, blockquote) {
        line-height: inherit !important;
        font-size: 13px !important;
        font-weight: 400 !important;
        padding: 0 !important;
        margin: 0 !important;
        position: relative !important;
        border: none !important;
        background: transparent !important;

        &:after {
            display: none !important;
        }
        &.text-lg {
            font-size: 1.75rem !important;
            font-weight: bold !important;
        }
    }
    > form > .form-field-type-panel:first-of-type {
        .form-field-type-columns:last-of-type {
            border-top: 1px solid @main-bg;
            margin-top: 1rem !important;
            padding-top: 0.5rem;
        }
    }
    #costtable {
        padding: 1rem 1rem 0;

        table {
            tbody {
                tr {
                    &:first-of-type {
                        border-bottom: solid 1px #efefef;

                        td {
                            padding: 6px 0 5px;
                            font-size: 14px;
                        }
                        & + tr {
                            td {
                                padding: 20px 0 0;
                            }
                        }
                    }
                    td {
                        padding: 6px 0 0;
                    }
                }
            }
        }
    }
    > form {
        .form-group {
            &.form-field-type-panel {
                .panel {
                    .panel-body {
                        .form-field-type-columns {
                            .form-field-type-checkbox {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
fieldset {
    border: solid 1px @greylines;
    border-radius: 2px;
    padding: 16px 16px 10px;

    legend {
        display: inline-block;
        width: auto;
        padding: 0 8px;
        color: #999;
        border-radius: 2px;
        margin-bottom: 0;
        margin-inline-start: -8px;
        font-weight: 400;

        &:after,
        &:before {
            display: none;
        }
    }
    :where(.formio-component-yourPaymentIsConfirmed, .form-field-type-mycontent, blockquote) {
        margin: 0 !important;
        margin-bottom: 1.5rem !important;
    }
}
.component-product-list-container {
    button {
        &:not(.close) {
            align-self: flex-start;
            border: 1px solid @blue;
            color: @blue;
            background-color: #ffffff;
            border-radius: 6px;
            font-weight: 500;
            order: 2;
            margin-bottom: 1.5rem;
        }
    }
    .component-product-list-modal {
        background: fade(@dark, 50%);

        .modal-dialog {
            position: fixed;
            top: 0;
            inset-inline-end: 0;
            bottom: 0;
            margin-top: 146px;
            margin-bottom: 0;
            height: calc(100vh - 146px);
            transition-duration: 0.15s;
            width: calc(100% / 3 * 2);

            .modal-title {
                font-size: 18px;
                text-transform: uppercase;
            }
            .modal-content {
                border-radius: 2px 0 0 0;
                height: 100%;

                .modal-body {
                    height: 100%;

                    iframe {
                        width: 100% !important;
                        height: calc(100% - 62px) !important;
                    }
                }
            }
            .modal-header {
                display: flex;
                justify-content: space-between;
            }
        }
        &.fade {
            .modal-dialog {
                transform: translateX(100%);
            }
            &.in {
                .modal-dialog {
                    transform: translateX(0%);
                }
            }
        }
        button.close.mt-2 {
            margin-top: 0px !important;
            width: 40px;
            font-size: 34px;
            opacity: 0.8;

            span {
                position: relative;
                top: -2px;
                font-weight: 400;
            }
        }
    }
}
.affix-menu-size-container.affix ~ .section-body {
    .component-product-list-container {
        .modal-dialog {
            margin-top: 66px;
            height: calc(100vh - 66px);

            .modal-content {
                .modal-body {
                    iframe {
                        height: calc(100% - 62px) !important;
                    }
                }
            }
        }
    }
}
body:has(.component-product-list-modal.in) {
    header#header {
        z-index: 1000;
    }
}
.form-field-type-mytabs {
    .form-group.form-field-type-panel > .panel > .panel-heading {
        border: none !important;
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
    }
    table {
        &.table {
            > tbody {
                > tr {
                    > :where(th, td) {
                        padding: 1rem 2rem;
                        vertical-align: middle;
                        line-height: 1.2;
                    }
                }
            }
        }
    }
}
.center-blocks {
    .form-field-type-mycontent {
        top: 50%;
        transform: translate(0, -50%);
    }
    .form-field-type-radio {
        top: 50%;
        transform: translate(0, -50%);
        position: relative;
        max-height: 20px;
    }
}
.address-block {
    .form-field-type-columns {
        background: #F7F7F7;
        margin: 0 0 30px !important;

        .form-field-type-mycontent {
            height: 100%;
            max-width: 100%;
            margin: 0 !important;
            background: none !important;
        }
        .col-sm-5{
            .form-field-type-mycontent {
                &:after{
                    background: none !important;
                }
            }
        }
    }
}

.transactions-statuses {
    .alert {
        color: white !important;
        text-transform: uppercase;
        font-size: 2rem !important;
        padding: 2.4rem !important;
        border-radius: 2px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
        margin-bottom: 3rem !important;
        font-weight: 500 !important;

        &.alert-success {
            background-color: #84C29B !important;
        }
        &.alert-warning {
            background-color: #99C8DB !important;
        }
        &.alert-danger {
            background-color: #CD6E74 !important;
        }
        &::after {
            display: none;
        }
    }
}

/* New formio */
.card {
    box-shadow: 0 1px 3px rgba(0,0,0,0.16);
    border-radius: 0;
    margin-bottom: 0;
    padding: 0;
    background-color: @basegrey;

    .card-header {
        background-color: transparent;
        border-radius: 0;
        border: none;
        padding: 2.5rem 2.5rem 0;

        &:only-child {
            padding-bottom: 2.5rem;
        }
        .card-title {
            color: @dark;
            font-size: 2rem;
            font-weight: 500;
            border: none;
            position: relative;
            padding: 0 0 1rem;
        }
        .item-count {
            font-size: 1.6rem;
            color: @grey;
        }
    }
}
@buttonAccentColor: var(--color-primary-600);
.formio-component-modal-wrapper,
.formio-form {
    .formio-component {
        margin: 3rem 0 0;
        padding: 0;
        [data-tooltip].fa-question-circle::before {
            left: inherit !important;
            margin-bottom: inherit !important;
            margin-left: inherit !important;
            right: inherit !important;
            top: inherit !important;
            position: inherit !important;
            bottom: inherit !important;
            pointer-events: all !important;
            transition: inherit !important;
            background: inherit !important;
            transform: inherit !important;
            border: inherit !important;
            transform-origin: inherit !important;
            box-shadow: inherit !important;
            content: "\f059" !important;
            opacity: inherit !important;
            height: inherit !important;
        }

        [data-tooltip].fa-question-circle::after {
            margin-bottom: inherit !important;
            right: inherit !important;
            content: "" !important;
            left: inherit !important;
            top: inherit !important;
            transition: inherit !important;
            box-shadow: inherit !important;
            transform-origin: inherit !important;
            padding: inherit !important;
            opacity: inherit !important;
            pointer-events: all !important;
            border: inherit !important;
            transform: inherit !important;
            font: inherit !important;
        }
        &.formio-hidden {
            &:first-child {
                & + .formio-component {
                    margin-top: 0;
                }
            }
        }
        p {
            margin: 0 0 1.5rem;
            line-height: 1.5;
            font-weight: 400;
    
            &:empty {
                display: none;
            }
        }
        :where(label, .col-form-label) {
            text-align: var(--label-text-align, start);
            width: 100% !important;
            margin-bottom: .75rem;
            font-size: 14px;
            color: @dark;
            text-transform: none !important;
            line-height: 1;
            margin-top: 0;

            &[style*="float: left; width: 30%; margin-inline-end: 3%; text-align: start;"] {
                width: auto !important;

                & + .input-group {
                    &[style*="width: 67%; margin-inline-start: 33%;"] {
                        width: auto !important;

                        > * {
                            &:first-child {
                                display: block;
                                margin-top: -6px;
                            }
                        }
                    }
                }
            }
            &.field-required {
                position: relative;

                &:after {
                    content: "\F069";
                    font-family: var(--fontawesome-font);
                    font-weight: 700;
                    -moz-osx-font-smoothing: grayscale;
                    -webkit-font-smoothing: antialiased;
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    text-rendering: auto;
                    line-height: 1;
                    color: #d56d72;/*@grey*/
                    font-size: .7rem;
                    vertical-align: super;
                }
            }
            & ~ :where(.formio-errors p, formio-errors p) {
                margin-top: 0.6rem;
            }
            & + .ui-select-container {
                .ui-select-choices.ui-select-dropdown.dropdown-menu {
                    /*top: 57px !important;*/
                }
            }
        }
        .input-group {
            width: 100% !important;
            margin-inline-start: 0 !important;
        }
        .input-group-addon {
            border-radius: 3px;
            border-color: transparent;/*@greylines*/
            background-color: @main-bg;
            color: @grey;
            position: relative;
    
            &:first-child {
                border-bottom-right-radius: 0 !important;
                border-top-right-radius: 0 !important;
    
                &::before {
                    content: ' ';
                    position: absolute;
                    top: 0;
                    inset-inline-end: 0;
                    height: 100%;
                    border-inline-end: 1px solid @greylines;
                    z-index: 3;
                    display: none;
                }
    
                +.form-control {
                    border-inline-start: none;
                    border-bottom-left-radius: 0 !important;
                    border-top-left-radius: 0 !important;
                    position: relative;
                }
            }
            &:last-child {
                border-bottom-left-radius: 0 !important;
                border-top-left-radius: 0 !important;
    
                &::before {
                    content: ' ';
                    position: absolute;
                    top: 0;
                    inset-inline-start: 0;
                    height: 100% - 1.5rem;
                    border-inline-start: 1px solid @greylines;
                    z-index: 3;
                    display: none;
                }
                & + .form-control {
                    border-inline-end: none;
                    border-bottom-right-radius: 0 !important;
                    border-top-right-radius: 0 !important;
                    position: relative;
                }
            }
            & + input:not(:last-child) {
                border-inline-end: none;
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }
        }
        .help-block {
            font-size: 1.1rem;
            text-align: start;
            display: block;
            line-height: 1.2;

            &:not(:empty) {
                margin-bottom: 1rem;
                margin-top: 0.7rem;
            }
        }
        .form-control {
            border-radius: 3px;
            border-color: @greylines;
            box-shadow: none;
            letter-spacing: 0.4pt;
            color: var(--main-text-color);
    
            &:where(:active, :focus, :hover) {
                /*box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);*/
                outline: solid 1px #e1e4ea;
            }
        }
        &.formio-component-panel {
            &.text-primary {
                color: @dark;
            }
            .card {
                .card-heading {
                    padding-top: 2.5rem;
                    padding-inline-start: 2.5rem;
                    padding-inline-end: 2.5rem;
                    margin: 0;

                    .card-title {
                        padding: 0;
                        line-height: 1;
                    }
                }
                .card-header {
                    .card-title {
                        padding: 0;
                        line-height: 1;
                        display: flex;
                        align-items: center;

                        i {
                            position: absolute;
                            inset-inline-end: 0;
                        }
                    }
                }
                .card-body {
                    .formio-component-table {
                        &:first-of-type {
                            margin-top: 0;
                        }
                    }
                    .formio-component-columns {
                        &:first-of-type {
                            margin-top: 0;
                        }
                    }
                    .formio-component {
                        &.formio-component-htmlelement {
                            &.notice {
                                padding: 0 2rem;
                            }
                        }
                    }
                }
            }
            &.payment-form-component {
                > .card {
                    > .card-body {
                        > .formio-component {
                            margin-top: 0 !important;
                            
                            .formio-component {
                                &.formio-component-htmlelement {
                                    margin-top: 0 !important;

                                    p {
                                        margin-bottom: 3px;
                                    }
                                }
                            }
                            &.formio-component-columns {
                                &:last-child {
                                    border-top: solid 1px #e1e4ea;
                                    align-items: center;
                                    margin-top: 1rem !important;
                                    padding-top: 1rem;

                                    h4 {
                                        margin: 0;
                                    }
                                    p {
                                        margin: 0;
                                    }
                                    div[class^="col-"] {
                                        &:nth-of-type(2) {
                                            .formio-component-htmlelement {
                                                p {
                                                    font-weight: 600;
                                                    margin-bottom: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.notice {
                border-inline-start: solid 3px var(--color-primary-600);

                > .card {
                    border: none !important;
                    background-color: white;
                    border-radius: 0 @roundedCorners @roundedCorners 0 !important;

                    > .card-header {
                        background: white !important;
                        border-radius: 0 @roundedCorners 0 0;
                    }
                    > .card-body {
                        border-radius: 0 0 @roundedCorners 0;

                        &:only-child {
                            border-radius: 0 @roundedCorners @roundedCorners 0;
                        }
                    }
                }
            }
        }
        &.formio-component-button {
            > button {
                border-radius: 2px;
                border: none;
                box-shadow: var(--button-shadow, none);
                font-weight: 500;
                color: white;
                font-size: 14px;
                min-width: fit-content;
                display: inline-flex !important;
                justify-content: center;
                align-items: center;

                i {
                    font-size: 14px;
                }
                &:hover {
                    color: white;
                }
                &.btn-xs {
                    font-size: 12px;
                    font-weight: 400;
                    padding: 0.8rem 1rem;
                }
                &.btn-sm {
                    font-size: 12px;
                    padding: 1rem;
                    min-height: 36px;
                }
                &.btn-lg {
                    font-size: 16px;
                    line-height: 1;
                    padding: 15px;
                }
                &.btn-block {
                    display: flex !important;
                }
            }
            &.btn-green {
                > button {
                    background-color: #6fbc8d;
    
                    &:hover {
                        background-color: #40a265;
                    }
                }
            }
            &.btn-blue {
                > button {
                    background-color: #3C8FB9;
    
                    &:hover {
                        background-color: #3680A6;
                    }
                }
            }
            &.btn-orange {
                > button {
                    background-color: #F3A72E;
    
                    &:hover {
                        background-color: #cc8b22;
                    }
                }
            }
            &.btn-red {
                > button {
                    background-color: #EA3D2F;
    
                    &:hover {
                        background-color: #ba3023;
                    }
                }
            }
            &.action-progress {
                button {
                    cursor: not-allowed;
                    color: #404451 !important;
                    opacity: 1 !important;
                    background: #efefef !important;
                    text-align: center !important;
                    border-color: #ccc;
                }
            }
        }
        &.formio-component-fieldset {
            padding: 0;

            fieldset {
                padding: 21px 16px 16px;

                legend {
                    margin-bottom: -10px;
                }
                .fieldset-body {
                    .formio-component {
                        &:first-child {
                            margin-top: 0;
                        }
                        &.formio-component-table {
                            table {
                                &.table {
                                    background-color: transparent;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.formio-component-columns {
            > div {
                &[class^="col-"] {
                    &:first-of-type {
                        padding-inline-start: 0;
                    }
                    &:nth-last-of-type(2) {
                        padding-inline-end: 0;
                    }
                    .formio-component {
                        &:first-of-type {
                            margin-top: 0;

                            &.top-label-space {
                                margin-top: 29px !important;

                                &.formio-component-button {
                                    margin-top: 29px !important;
                                }
                                &.formio-component-checkbox {
                                    margin-top: calc(12px + 0.8rem) !important;
                                }
                            }
                        }
                    }
                }
            }
            &.default-pay-form-payments {
                margin-top: 0;
                align-items: center;
                
                > .col-md-1 {
                    flex: 0;
                }
                .formio-component-checkbox {
                    &.radio-info {
                        margin-top: 0;
                    }
                    .form-check.checkbox {
                        margin-top: 0;

                        .form-check-label {
                            .form-check-input {
                                opacity: 1;
                                z-index: 99;
                                position: relative;
                                margin: 0;
                            }
                        }
                    }
                }
                .formio-component-htmlelement {
                    margin-top: 0;
                }
            }
            &.formio-component-totalPrice {
                .formio-component-htmlelement {
                    margin-top: 0;
                }
            }
            &.i-swear {
                div {
                    &:first-child {
                        flex: 0 0 26px;

                        .formio-component-checkbox {
                            height: 100%;

                            .form-check {
                                height: 100%;
                                display: flex;
                                align-items: center;

                                label {
                                    &.form-check-label {
                                        display: flex;
                                        margin-top: -1px;

                                        input {
                                            &.form-check-input {
                                                margin: 0;
                                                position: relative;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        & + div {
                            padding-inline-start: 0;
                            flex: auto;
                        }
                    }
                }
            }
            &.adjust-columns {
                > div {
                    &[class^="col-"] {
                        &:has(.formio-component-button) {
                            min-width: fit-content;
                        }
                    }
                }
                // Custom columns sizing
                > :is(.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12) {
                    width: 100%;
                    min-width: initial;
                    max-width: var(--custom-sizing-max-width, initial);
                }
                @media (min-width: 992px) {
                    flex-wrap: nowrap !important;
                }
                .col-md-1 {
                  --custom-sizing-max-width: 8.33333333%;
                }
                .col-md-2 {
                  --custom-sizing-max-width: 16.66666667%;
                }
                .col-md-3 {
                  --custom-sizing-max-width: 25%;
                }
                .col-md-4 {
                  --custom-sizing-max-width: 33.33333333%;
                }
                .col-md-5 {
                  --custom-sizing-max-width: 41.66666667%;
                }
                .col-md-6 {
                  --custom-sizing-max-width: 50%;
                }
                .col-md-7 {
                  --custom-sizing-max-width: 58.33333333%;
                }
                .col-md-8 {
                  --custom-sizing-max-width: 66.66666667%;
                }
                .col-md-9 {
                  --custom-sizing-max-width: 75%;
                }
                .col-md-10 {
                  --custom-sizing-max-width: 83.33333333%;
                }
                .col-md-11 {
                  --custom-sizing-max-width: 91.66666667%;
                }
                .col-md-12 {
                  --custom-sizing-max-width: 100%;
                }
            }
            &.payment-fee {
                .formio-component-htmlelement[class*="price"] {
                    p[ref="html"] {
                        display: flex;
                        justify-content: flex-end;
                    }
                }
            }
        }
        &.formio-component-mycontent {
            background: none !important;
            padding: 0 !important;
    
            p {
                margin: 0 0 2.6rem;
    
                &:last-child {
                    margin-bottom: 0;
                }
            }        
            &.alert.alert-danger {
                padding: 15px !important;
                margin-bottom: 2rem !important;
                background: #f3e1e2 !important;
                border-inline-start: solid 4px #d56d72 !important;
                box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
    
                &::after {
                    display: none !important;
                }
            }
            &.notice {
                background: #f5f6f8 !important;
                padding: 2rem !important;
                border-inline-start: solid 3px #3c8fb9;
            }
            & + .formio-component-panel {
                margin-top: 3rem;
            }
        }
        &.formio-component-htmlelement {
            &.notice {
                background: #f5f6f8 !important;
                padding: 2rem;
                border-inline-start: solid 3px #3c8fb9;
            }
            & + .formio-component-panel {
                margin-top: 3rem;
            }
            p {
                margin: 2.6rem 0 0;
                font-size: 14px;

                &:first-child {
                    margin-top: 0;
                }
                span {
                    &.text-tiny {
                        font-size: 9px;

                        span {
                            background-color: transparent !important;
                        }
                    }
                    &.text-small {
                        font-size: 11px;
                    }
                    &.text-big {
                        font-size: 18px;
                    }
                    &.text-huge {
                        font-size: 24px;
                    }
                }
            }
            ul {
                margin-top: 13px;
                margin-bottom: 0;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
        &.formio-component-checkbox {
            margin-bottom: 0;
            
            .checkbox {
                margin-top: 0;
                padding-top: 13px;
  
                label {
                    margin-top: 1px;
                    padding-inline-start: 26px;
  
                    span {
                        color: @accentColor50;
                        font-size: @defaultTextSize;
                        font-weight: 400;
                        padding-inline-start: 0px;
                        line-height: 19px;
                        margin-top: 0;
    
                        &::before {
                            color: @accentColor50;
                            font-size: @defaultTextSize;
                            font-weight: 400;
                            top: 1px;
                            inset-inline-start: -26px;
                            line-height: 20px;
                        }
                    }
                    input {
                        &[type="radio"] {
                            appearance: inherit;
  
                            &::before {
                                -webkit-font-smoothing: antialiased;
                                display: inline-block;
                                font-style: normal;
                                font-variant: normal;
                                text-rendering: auto;
                                line-height: 1;
                                font-family: "Font Awesome 5 Pro";
                                font-weight: 400;
                                font-size: @defaultTextSize;
                                content: "\f111";
                                color: @accentColor50;
                            }
                            & + span {
                                font-size: @defaultTextSize;
                                font-weight: 600;
                                color: @accentColor50;
                                margin-inline-start: 3px;
                                position: relative;
                                line-height: 14px;
                                padding-inline-start: 0;
                            }
                            &:checked {
                                &::before {
                                    content: "\f192";
                                    font-weight: 900;
                                    color: var(--color-primary-600);
                                }
                                & + span {
                                    color: var(--color-primary-600);
                                }
                            }
                            &:focus {
                                outline: none;
                            }
                        }
                        &:checked {
                            & + span {
                                color: var(--color-primary-600);
        
                                &::before {
                                    color: var(--color-primary-600);
                                    font-weight: 600;
                                }
                            }
                        }
                        &[type="checkbox"] {
                            transform: scale(0);
                        }
                    }
                    &:hover {
                        span {
                            color: var(--color-primary-600);
    
                        &::before {
                            color: var(--color-primary-600);
                        }
                        }
                        input {
                            &[type="radio"] {
                                appearance: inherit;
    
                                &::before {
                                    color: var(--color-primary-600);
                                }
                                & + span {
                                    color: var(--color-primary-600);
                                }
                            }
                        }
                    }
                    &.field-required {
                        &::after {
                            content: " ";
                            display: inline-block;
                            background-color: @errorColor70;
                            vertical-align: unset;
                            width: 6px;
                            height: 6px;
                            border-radius: 5px;
                            position: relative;
                            top: -1px;
                            inset-inline-start: 8px;
                            min-width: 6px;
                        }
                    }
                }
            }
            &:is(.remove-top-space, .remove-inner-top-space) {
                .checkbox {
                    padding-top: 0;

                    .form-check-label {
                        margin-top: 2px;
                    }
                }
            }
            &.required {
                label.field-required {
                    &::after {
                        display: none;
                    }
                }
            }
            &.formio-component-label-hidden {
                .checkbox label {
                    margin-top: 0px;
                    padding-inline-start: 0;

                    input.form-check-input {
                        transform: scale(1);
                        appearance: none;
                        position: initial;
                        margin-inline-start: 0px;
                        margin: 0;
                        padding: 0;

                        &::after {
                            content: "";
                            -webkit-font-smoothing: antialiased;
                            display: inline-block;
                            font-style: normal;
                            font-variant: normal;
                            text-rendering: auto;
                            line-height: 14px;
                            font-family: "Font Awesome 5 Pro";
                            font-weight: 400;
                            font-size: 16px;
                            content: "\F0C8";
                            color: rgba(70, 81, 170, 0.5);
                            top: -3px;
                            position: relative;
                        }
                        &:focus {
                            outline: none;
                        }
                        &:checked {
                            &::after {
                            content: "\F14A";
                            font-weight: 900;
                            color: var(--color-primary-600);
                            }
                        }
                    }
                }
            }
            & + .formio-component-checkbox {
                margin-top: 1rem;
            }
            &.check-button {
                --button-bg-color: @buttonAccentColor;
                --button-secondary-bg-color: @accentColor10;
                --button-shadow-y: 9px;
                --button-shadow-blur: 20px;
                --button-inline-padding: 25px;
                --button-block-padding: 12px;
                --button-font-size: 16px;
                --button-border-radius: @roundedCorners;
                --button-text-color: @whiteColor;
                --button-text-color-hover: @whiteColor;
                --button-outline-bg-color: transparent;

                .checkbox {
                    label {
                        display: inline-block;
                        width: auto !important;
                        min-width: fit-content;
                        border-radius: var(--button-border-radius);
                        outline: solid var(--button-border-width, 0px) var(--button-border-color, transparent);
                        background-color: var(--button-bg-color);
                        padding-inline-start: var(--button-inline-padding) !important;
                        padding-inline-end: var(--button-inline-padding);
                        padding-top: calc(var(--button-block-padding) - var(--button-border-width, 0px));
                        padding-bottom: calc(var(--button-block-padding) - var(--button-border-width, 0px));
                        font-size: var(--button-font-size) !important;
                        font-weight: 500;
                        color: var(--button-text-color) !important;
                        text-transform: none;
                        position: relative;
                        z-index: 1;
                        line-height: 1 !important;
                        margin-top: 0px;
    
                        span {
                            color: inherit !important;
                            line-height: inherit !important;
                            font-size: inherit !important;
                            font-weight: inherit;
                            background: transparent;
    
                            &::before {
                                display: none;
                            }
                        }
                        &:hover {
                            --button-shadow: var(--button-shadow-hover);
                            opacity: 0.9;
                            color: var(--button-text-color-hover);
                        }
                        input {
                            position: absolute !important;
                        }
                        &:has(input:checked) {
                            --button-border-width: 5px;
                            --button-border-color: var(--color-blue-200);
                        }
                    }
                }
                .form-check.checkbox {
                    padding-top: 0px;
                }
                &:is(.btn-secondary, .light-color) {
                    --button-text-color: @darkTextColor;
                    --button-text-color-hover: @darkTextColor;
      
                    .checkbox {
                        label {
                            background-color: var(--button-secondary-bg-color);
                        }
                    }
                }
                &.outline-button {
                    --button-text-color: var(--button-bg-color);
                    --button-text-color-hover: var(--button-bg-color);
                    --button-border-width: 1px;
                    --button-border-color: var(--button-bg-color);
      
                    &:is(.btn-secondary, .light-color) {
                        --button-border-color: var(--button-secondary-bg-color);
                        --status-secondary-outline-border-color: @accentColor50;
                    }
                    .checkbox {
                        label {
                            background-color: var(--button-outline-bg-color);
                            margin-top: 1px;
                        }
                    }
                }
                &.button-status {
                    --button-border-radius: 50px;
                    --button-shadow: none;
                    --button-inline-padding: 20px;
                    --status-bg-color: @buttonAccentColor;
                    --status-secondary-bg-color: #E6E6EA;
                    --status-outline-bg-color: transparent;
                    --status-text-color: #FFFFFF;
                    
                    .checkbox {
                        label {
                            background-color: var(--status-bg-color);
                            color: var(--status-text-color);
                            font-weight: 600;
                        }
                    }
                    &.outline-button {
                        --status-outline-border-color: #070B34;
                        
                        .checkbox {
                            label {
                                background-color: var(--status-outline-bg-color);
                                border-color: var(--status-outline-border-color);
                                color: var(--status-outline-border-color);
                            }
                        }
                        &:is(.btn-secondary, .light-color) {   
                            --status-outline-secondary-bg-color: transparent;
                            
                            .checkbox {
                                label {
                                    border-color: var(--status-secondary-outline-border-color);
                                    color: var(--status-outline-border-color);
                                    background-color: var(--status-outline-secondary-bg-color);
                                }
                            }
                        }
                    }
                    &:is(.btn-secondary, .light-color) {
                        --status-text-color: var(--main-text-color);
                        --status-text-color-hover: var(--main-text-color);
                        
                        .checkbox {
                            label {
                                background-color: var(--status-secondary-bg-color);
                                color: var(--status-secondary-text-color);
                            }
                        }
                    }
                }
                &.btn-green {
                    --button-bg-color: #10B981;
                    --button-secondary-bg-color: rgba(@successNoticeRGB, 0.35);
                    --status-bg-color: #4BC394;
                    --status-secondary-bg-color: #E1F0EE;
                    --status-text-color: white;
                    --status-secondary-text-color: #10B981;
                    --status-secondary-outline-border-color: #9CCEC7;
                    --status-outline-border-color: #10B981;
                }
                &.btn-blue {
                    --button-bg-color: @infoNotice;
                    --button-secondary-bg-color: rgba(@infoNoticeRGB, 0.35);
                    --status-bg-color: #4CAAE5;
                    --status-secondary-bg-color: #E4F2FB;
                    --status-text-color: white;
                    --status-secondary-text-color: #1D86C8;
                    --status-secondary-outline-border-color: #8CC7EE;
                    --status-outline-border-color: #1D86C8;
                }
                &.btn-orange {
                    --button-bg-color: @warningNotice;
                    --button-secondary-bg-color: rgba(@warningNoticeRGB, 0.5);
                    --status-bg-color: #FFA385;
                    --status-secondary-bg-color: #FFF0EB;
                    --status-text-color: white;
                    --status-secondary-text-color: #FF7233;
                    --status-secondary-outline-border-color: #FFA385;
                    --status-outline-border-color: #FF7233;
                }
                &.btn-red {
                    --button-bg-color: @errorNotice;
                    --button-secondary-bg-color: rgba(@errorNoticeRGB, 0.35);
                    --status-bg-color: #F75F5F;
                    --status-secondary-bg-color: #FEE7E7;
                    --status-text-color: white;
                    --status-secondary-text-color: #F53D3D;
                    --status-secondary-outline-border-color: #FA8585;
                    --status-outline-border-color: #F53D3D;
                }
                &.has-success {
                    .checkbox {
                        label {
                            background-color: #3680A6;
                        }
                    }
                }
                &:not(.has-success) {
                    .checkbox {
                        label {
                            input {
                                &.ng-untouched.ng-not-empty {
                                    & + span {
                                        padding: 4.5px 14px;
                                        margin: -4.5px -14px;
                                        border-radius: 2px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.check-switch {
                display: flex;
                padding-inline-start: 0;
    
                .checkbox {
                    label {
                        display: flex;
                        width: 100%;
                        align-items: center;
    
                        span {
                            padding-inline-start: 3rem;
    
                            &::before {
                                content: "";
                                font-family: "Font Awesome 5 Pro";
                                font-weight: 900;
                                display: inline-block;
                                color: #A3A3A3;
                                font-size: 2rem;
                                transform: scale(-1, 1);
                            }
                        }
                        &.form-check-label {
                            input {
                                &.form-check-input {
                                    &:checked {
                                        & + span {
                                            &::before {
                                                transform: scale(1, 1) !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.has-success {
                    .checkbox {
                        label {
                            span {
                                &::before {
                                    color: #6fbc8d;
                                    transform: scale(1, 1);
                                }
                            }
                        }
                    }
                }
                &:not(.has-success) {
                    .checkbox {
                        label {
                            input {
                                &.ng-untouched.ng-not-empty {
                                    & + span {
                                        &::before {
                                            color: #6fbc8d;
                                            transform: scale(1, 1);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.top-label-space {
                .form-check {
                    padding-top: 11px;
                }
            }
            &.remove-space-top {
                .form-check {
                    &.checkbox {
                        margin-top: 10px;
                    }
                }
            }
        }
        &.formio-component-textarea {
            line-height: 1;
    
            &.service-response-msg {
                height: 100%;
    
                > div {
                    height: 100%;
    
                    > .input-group {
                        height: 100%;
    
                        textarea.form-control {
                            position: absolute;
                            display: table-cell;
                            top: 31px;
                            inset-inline-end: 0;
                            bottom: 6px;
                            inset-inline-start: 0;
                            -webkit-appearance: menulist;
                            border: none;
                            border-inline-start: solid 3px #3C8FB9;
                            border-radius: 0 !important;
                            font-size: 13px;
                            background: rgba(239, 239, 239, 0.5);
                            font-weight: 600;
    
                            &.ng-empty {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        &.formio-component-table {
            table {
                border: none;

                &.table {
                    table-layout: fixed;

                    > tbody {
                        > tr {
                            > td {
                                border: none ! important;
                                padding: 0 12px;
                                vertical-align: top;
                                line-height: 1;

                                :where(select, input[type="text"], input[type="number"], input[type="email"]) {
                                    border-radius: 3px !important;
                                    height: 36px !important;
                                }
                                .print-button {
                                    margin: 0;
                                }
                                .formio-component-radio {
                                    .input-group {
                                        .radio-inline {
                                            margin-top: 10px;
                                        }
                                    }
                                }
                                .formio-component-panel {
                                    margin-top: 3rem;
                                }
                                &:empty {
                                    padding: 18px 0 !important;
                                    height: 36px;
                                }
                            }
                            &:first-child {
                                > :where(td > .form-group, td > .formio-component) {
                                    &:first-child:not(.ng-hide) {
                                        margin-top: 0;

                                        &.top-label-space {
                                            margin-top: calc(14px + 0.8rem) !important;
                                        }
                                    }
                                }
                                & ~ tr {
                                    > td {
                                        > .formio-component {
                                            &:first-child:not(.ng-hide) {
                                                margin-top: 3rem;
                                            }
                                            &.formio-component-radio {
                                                .form-radio {
                                                    &:first-child {
                                                        margin-top: 53px;
                                                    }
                                                    .form-check {
                                                        &:first-child {
                                                            margin-top: 64px;
                                                        }
                                                    }
                                                }
                                                &.remove-space-top {
                                                    .form-radio {
                                                        margin-top: 30px;
                                                    }
                                                }
                                            }
                                            &.formio-component-selectboxes {
                                                .form-radio {
                                                    &:first-child {
                                                        margin-top: 65px;
                                                    }
                                                }
                                                &.remove-space-top {
                                                    .form-radio {
                                                        &:first-child {
                                                            margin-top: 42px;
                                                        }
                                                    }
                                                }
                                            }
                                            &.formio-component-checkbox {
                                                .form-check {
                                                    &.checkbox {
                                                        margin-top: 63px;
                                                    }
                                                }
                                                &.remove-space-top {
                                                    .form-check {
                                                        &.checkbox {
                                                            margin-top: 40px;
                                                        }
                                                    }
                                                }
                                            }
                                            &.formio-hidden {
                                                &:first-child {
                                                    & + .formio-component {
                                                        margin-top: 3rem;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.table-sm {
                        table-layout: auto;
                    }
                }
            }
            &.layout-auto {
                table {
                    table-layout: auto;
                }
            }
            &.table-responsive {
                overflow-x: initial;
            }
        }
        &.formio-component-number {
            line-height: 1;
            
            > div {
                line-height: 1;

                .input-group {
                    display: flex;
                    align-items: center;
                    height: 36px;

                    :where(.input-group-prepend, .input-group-append) {
                        flex: auto;
                        white-space: nowrap;
                        border: solid 1px rgba(64, 68, 81, 0.16);
                        background-color: #efefef;
                        color: #9AA7B7;
                        display: flex;
                        height: 100%;
                        align-items: center;
                        border-radius: 3px 0 0 3px;

                        & + input.form-control {
                            border-bottom-left-radius: 0 !important;
                            border-top-left-radius: 0 !important;
                            border-inline-start: none;

                            &:nth-last-child(2) {
                                border-inline-end: none;
                                border-bottom-right-radius: 0 !important;
                                border-top-right-radius: 0 !important;
                            }
                        }
                        .input-group-text {
                            font-size: 14px;
                            padding: 4.5px 14px;
                        }
                    }
                    .input-group-append {
                        border-radius: 0 3px 3px 0;
                    }
                    input.form-control {
                        flex: auto;

                        &:first-child {
                            border-inline-end: none;
                            border-bottom-right-radius: 0 !important;
                            border-top-right-radius: 0 !important;
                        }
                    }
                }
            }
        }
        &.formio-component-currency {
            line-height: 1;
            
            > div {
                line-height: 1;

                .input-group {
                    display: flex;
                    align-items: center;
                    height: 36px;

                    :where(.input-group-prepend, .input-group-append) {
                        flex: auto;
                        white-space: nowrap;
                        border: solid 1px rgba(64, 68, 81, 0.16);
                        background-color: #efefef;
                        color: #9AA7B7;
                        display: flex;
                        height: 100%;
                        align-items: center;
                        border-radius: 3px 0 0 3px;

                        & + input.form-control {
                            border-bottom-left-radius: 0 !important;
                            border-top-left-radius: 0 !important;
                            border-inline-start: none;

                            &:nth-last-child(2) {
                                border-inline-end: none;
                                border-bottom-right-radius: 0 !important;
                                border-top-right-radius: 0 !important;
                            }
                        }
                        .input-group-text {
                            font-size: 14px;
                            padding: 4.5px 14px;
                        }
                    }
                    .input-group-append {
                        border-radius: 0 3px 3px 0;
                    }
                    input.form-control {
                        flex: auto;

                        &:first-child {
                            border-inline-end: none;
                            border-bottom-right-radius: 0 !important;
                            border-top-right-radius: 0 !important;
                        }
                    }
                }
            }
        }
        &.formio-component-textfield {
            line-height: 1;

            > div {
                line-height: 1;

                label.col-form-label {
                    i.glyphicon-question-sign {
                        margin-top: -2px;
                    }
                }
                .input-group {
                    display: flex;
                    align-items: center;
                    height: 36px;

                    :where(.input-group-prepend, .input-group-append) {
                        flex: auto;
                        white-space: nowrap;
                        border: solid 1px rgba(64, 68, 81, 0.16);
                        background-color: #efefef;
                        color: #9AA7B7;
                        display: flex;
                        height: 100%;
                        align-items: center;
                        border-radius: 3px 0 0 3px;

                        & + input.form-control {
                            border-bottom-left-radius: 0 !important;
                            border-top-left-radius: 0 !important;
                            border-inline-start: none;

                            &:nth-last-child(2) {
                                border-inline-end: none;
                                border-bottom-right-radius: 0 !important;
                                border-top-right-radius: 0 !important;
                            }
                        }
                        .input-group-append {
                            border-radius: 0 3px 3px 0;
                        }
                        .input-group-text {
                            font-size: 14px;
                            padding: 4.5px 14px;
                        }
                    }
                    input.form-control {
                        flex: auto;

                        &:first-child {
                            border-inline-end: none;
                            border-bottom-right-radius: 0 !important;
                            border-top-right-radius: 0 !important;
                        }
                    }
                }
            }
        }
        &.formio-component-select {
            line-height: 1;

            .choices__list {
                &.is-active {
                    .choices__list {
                        .choices__item {
                            color: #404451;
                        }
                    }
                }
            }
            &.hidden-field {
                display: none !important;
            }
        }
        &.formio-component-radio {
            line-height: 1;

            label.col-form-label {
                margin-top: 0 !important;
            }
            .form-radio {
                margin: 0;

                .form-check {
                    line-height: 1;
                    margin-bottom: 1rem;

                    label {
                        &.form-check-label {
                            min-height: auto;
                            position: relative;
                            margin-bottom: 0;

                            > input {
                                &.form-check-input {
                                    margin: 0;
                                    inset-inline-start: 0;
                                    top: -1px;
                                    width: 14px;
                                    height: 14px;
                                }
                            }
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;

                        label {
                            &.form-check-label {
                                margin-bottom: 0;
                            }
                        }
                    }
                    &:first-child {
                        margin-top: 11px;
                    }
                }
                .form-check-inline {
                    line-height: 16px;
                    display: inline-flex;
                    margin-inline-end: 28px;

                    label {
                        &.form-check-label {
                            display: block;
                            line-height: 1;
                            min-height: auto;
                            position: relative;
                            margin-bottom: 0;
                            margin-top: 1px;

                            > input {
                                &.form-check-input {
                                    top: -1px;
                                    margin: 0;
                                    inset-inline-start: 0;
                                    width: 14px;
                                    height: 14px;
                                }
                            }
                        }
                    }
                    &:nth-last-of-type(1) {
                        margin-inline-end: 0;
                    }
                }
                &:first-child {
                    margin-top: 23px;
                }
            }
            &.remove-space-top {
                .form-radio {
                    margin-top: 0;
                }
            }
        }
        &.formio-component-editgrid {
            max-width: 100%;
            width: fit-content;
            --custom-editgrid-listgroup-bg-color: white;

            > button {
                border: 1px solid #3C8FB9;
                color: #3C8FB9;
                background-color: transparent;
                border-radius: 2px !important;
                font-weight: 400;
                padding: 0.5rem 1rem;
                line-height: 1;
            }
            .editgrid-listgroup {
                background-color: var(--custom-editgrid-listgroup-bg-color, white);
                border-radius: 3px;
                border: solid 1px #D5D6D9;
                margin-bottom: 0px;

                .list-group-item {
                    width: fit-content;
                    min-width: 100%;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 0;
                    background-color: var(--custom-editgrid-listgroup-bg-color, white);
                    border: none;
                    padding: 0px !important;
                    line-height: 1;
                    text-align: start;
                    font-size: 15px;
                    border-radius: 3px 3px 0 0 !important;

                    &.list-group-footer {
                        > .row {
                            > div {
                                &:last-of-type {
                                    flex: 0;
                                    min-width: 64px;
                                    max-width: fit-content;
                                }
                            }
                        }
                    }
                    &.list-group-header {
                        font-weight: 500;
                        font-size: 12px;
                        padding: 0 2.5rem 0 2.5rem;
                        letter-spacing: 0.5px;
                        background: #eff0f1;

                        > .row {
                            align-items: flex-start;

                            > div {
                                border-inline-end: solid 1px #D5D6D9;

                                &:last-of-type {
                                    border-inline-end: none;
                                    flex: 0;
                                    min-width: 64px;
                                    width: 64px;
                                    padding: 0 !important;
                                    max-width: fit-content;
                                    align-self: flex-start;
                                    text-align: end;
                                }
                            }
                        }
                    }
                    &:only-child {
                        border-bottom: none;
                    }
                    > .row:not(.formio-component-columns) {
                        display: flex;
                        align-items: center;

                        > div {
                            width: auto;
                            word-break: break-word;
                            flex: 1;
                            min-width: 170px;
                            max-width: 240px;
                            padding-top: 5px;
                            padding-bottom: 5px;
                        }
                        &::after,
                        &::before {
                            display: none;
                        }
                    }
                    > .row {
                        &.formio-component-columns {
                            align-items: start;
                            
                            &:first-of-type {
                                margin-top: 0;
                            }
                        }
                        .btn-group {
                            .btn {
                                background: transparent;
                                border: none;

                                &.removeRow {
                                    color: #f44336;
                                }
                            }
                        }
                    }
                    .editgrid-actions {
                        margin-top: @componentSeparation;
                    }
                    &:not(.list-group-header) {
                        &:not(.list-group-footer) {
                            > .row {
                                &:not(.formio-component-columns) {
                                    > div {
                                        padding-top: 8px;
                                        padding-bottom: 8px;
                                        line-height: 1.2;

                                        &:last-of-type {
                                            .btn-group {
                                                float: none !important;
                                                display: flex;
                                                align-items: center;
                                                background: white;
                                                transition: all 0.3s;                                                

                                                .btn-icon-toggle {
                                                    margin: 0;
                                                    display: inline-flex;
                                                    height: 100%;
                                                    border-radius: 0 !important;
                                                    align-items: center;
                                                    justify-content: center;
                                                    color: #9AA7B7;

                                                    &:hover {
                                                        color: #404451;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .help-block {
                        line-height: 2;
                        margin: 0;
                    }
                }
            }
            &.search-result-list {
                .editgrid-listgroup {
                    .list-group-item {
                        &:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) {
                            cursor: pointer;
                            
                            &:where(:hover, .selected),
                            :where(:hover, .selected) {
                                background-color: #f5f6f8 !important;

                                .eg-action-col {
                                    .btn-group {
                                        background-color: #f5f6f8 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.has-open-row {
                > .editgrid-listgroup > .list-group-item:not(.list-group-header, .list-group-footer) {
                    > .row > .eg-action-col {
                        .editRow {
                            display: none !important;
                        }
                    }
                }
                > button {
                    &.btn {
                        display: none !important;
                    }
                }
                &:has(.list-group-header + .new-entry:last-child),
                &:has(.list-group-header + .new-entry + .list-group-footer) {
                    > .editgrid-listgroup > .list-group-item:is(.list-group-header, .list-group-footer) {
                        display: none;
                    }
                }
            }
            &.no-rows {
                .list-group-item:is(.list-group-header, .list-group-footer) {
                    display: none !important;
                }
            }
        }
        &.formio-component-datetime {
            line-height: 1;

            .input-group {
                input.form-control {
                    border-radius: 3px 0 0 3px !important;
                    display: table-cell;
    
                    & ~ .input-group-append {
                        display: table-cell;
                        vertical-align: middle;
                        width: 1%;

                        .input-group-text {
                            line-height: 25px;
                            border-radius: 0 3px 3px 0;
                            padding: 5px 14px;
                            background-color: #fff;
                            color: #9aa7b7;
                            display: block;
                            border: solid 1px rgba(64,68,81,.16);
                            border-inline-start: 0;
                            border-color: transparent;

                            &:where(:active, :focus) {
                                border-color: transparent;
                            }
                        }
                    }
                }
            }
        }
        &.formio-component-selectboxes {
            line-height: 1;

            .form-radio {
                line-height: 1;
                margin: 13px 0 0;

                .form-check {
                    margin-bottom: 1rem;

                    .form-check-label {
                        line-height: 1;
                        display: flex;
                        min-height: auto;
                        align-items: center;
                        padding: 0;
                        margin-bottom: 0;

                        input {
                            &.form-check-input {
                                margin: 0;
                                margin-inline-end: 0.7rem;
                                position: relative;
                                top: -1px;
                                width: 14px;
                                height: 14px;
                            }
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;

                        label {
                            &.form-check-label {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                .form-check-inline {
                    display: inline-flex;
                    margin-inline-end: 28px;

                    .form-check-label {
                        line-height: 1;
                        display: flex;
                        min-height: auto;
                        align-items: center;
                        padding: 0;
                        margin-bottom: 8px;

                        input {
                            &.form-check-input {
                                margin: 0;
                                margin-inline-end: 0.7rem;
                                position: relative;
                                top: -1px;
                                width: 14px;
                                height: 14px;
                            }
                        }
                    }
                }
                &:first-child {
                    margin-top: 35px;
                }
            }
            &.remove-space-top {
                .form-radio {
                    margin-top: 12px;
                }
            }
        }
        &.formio-component-url {
            label {
                &.col-form-label {
                    display: block;
                }
            }
            > div {
                input {
                    &.form-control {
                        border-radius: 3px;
                        border-color: rgba(64, 68, 81, 0.16);
                        box-shadow: none;
                        letter-spacing: 0.4pt;
                        color: #404451;

                        &:focus {
                            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
                        }
                    }
                }
            }
        }
        &.formio-component-email {
            line-height: 1;
        }
        &.formio-component-hidden {
            display: none;
        }
        &.formio-hidden {
            display: none;
        }
        &.formio-error-wrapper {
            background-color: transparent;

            .formio-errors {
                .form-text {
                    font-size: 11px;
                    margin-top: 5px;
                }
            }
            .form-control {
                border-color: #d56d72;
            }
        }
        &.formio-component-file {
            margin-top: 0;

            .fileSelector {
                color: #404451;

                a {
                    color: #3b8fb9;
                }
                i {
                    font-size: 17px;
                }
            }
        }
        &.formio-component-tabs {
            > .card {
                background-color: transparent;

                > .card-header {
                    padding: 0;

                    .card-header-tabs {
                        min-height: auto;

                        .nav-item {
                            &:not(.active) {
                                &::before {
                                    content: "";
                                    width: 2px;
                                    background-color: #c6c8cc;
                                    height: 60%;
                                    position: absolute;
                                    inset-inline-start: 0;
                                    top: calc(20% - 2px);
                                }
                                &:first-child {
                                    &::before {
                                        display: none;
                                    }
                                }
                            }
                            &.active {
                                & + .nav-item {
                                    &::before {
                                        display: none;
                                    }
                                }
                            }
                            .nav-link {
                                opacity: 1;

                                &.active {
                                    z-index: 9;
                                }
                                &:hover {
                                    color: #404451;
                                }
                            }
                        }
                    }
                }
                > .card-body {
                    &.tab-pane {
                        padding: 2.5rem 2.5rem 0.8rem;

                        > .formio-component {
                            &:last-child {
                                margin-bottom: 0;
                            }
                            &:first-child {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
        /* Custom Classes */
        &:where(.top-space-zero, .margin-top-zero) {
            margin-top: 0 !important;
        }
        &.top-space {
            margin-top: 3rem;
        }
        &.remove-top-space {
            &.formio-component-radio {
                .form-radio {
                    margin-top: -10px;
                    
                    .form-check-inline {
                        margin-top: 1px;
                    }
                }
            }
            &.formio-component-selectboxes {
                .form-radio {
                    margin-top: 1px;

                    .form-check {
                        margin-top: 2px;
                    }
                    .form-check-inline {
                        display: inline-grid;
                        margin-inline-end: 28px;
                        position: relative;

                        .form-check-label {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        &.value-label-style {
            input {
                &.form-control {
                    appearance: none;
                    background: transparent;
                    border: none;
                    padding: 0;
                    letter-spacing: 0 !important;
                    font-size: 1.4rem;
                    font-weight: 500;
                }
            }
        }
    }
}

#base {
    #content {
        div + .affix-menu-size-container {
            height: 95px;
        }
        .section-body {
            > .contain-lg {
                > i {
                    &.fa-info {
                        display: none;
                    }
                }
                #rolereview {
                    .formio-component-form {
                        .formio-form {
                            > .formio-component-tabs {
                                .card {
                                    box-shadow: none;

                                    > .card-header {
                                        ul {
                                            &.card-header-tabs {
                                                background-color: white;
                
                                                li {
                                                    &.nav-item {
                                                        max-width: fit-content;
                                                        height: auto;
                
                                                        &::before {
                                                            display: none;
                                                        }
                                                        &.active {
                                                            a {
                                                                &.nav-link {
                                                                    background-color: #f5f6f8;
                                                                    box-shadow: 0 4px 3px rgba(0, 0, 0, 0.16);
                                                                    z-index: 1;
                                                                }
                                                            }
                                                        }
                                                        a {
                                                            &.nav-link {
                                                                border-radius: 3px 3px 0 0;
                                                                text-transform: none;
                                                                font-size: 2rem;
                                                                padding-inline-start: 3rem;
                                                                padding-inline-end: 3rem;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    > .card-body {
                                        &.tab-pane {
                                            z-index: 2;
                                            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
                                            background: #f5f6f8;
                                            padding: 0;

                                            > .formio-component-panel {
                                                > .card {
                                                    > .card-body {
                                                        padding: 2.7rem 2.5rem 2.5rem !important;
            
                                                        > .formio-component {
                                                            &.formio-component-columns {
                                                                margin-top: 0;
                                                                align-items: center;
                                                                border: solid 1px #e3e3e6;
            
                                                                &:nth-child(odd) {
                                                                    background-color: rgb(242, 242, 242);
                                                                }
                                                                & + .formio-component {
                                                                    &.formio-component-columns {
                                                                        border-top: none;
                                                                    }
                                                                }
                                                                > div {                                                        
                                                                    height: 100%;
                                                                    padding: 6px 12px;
            
                                                                    &:first-of-type {
                                                                        border-inline-end: solid 1px #e3e3e6;
            
                                                                        & + div {
                                                                            border-inline-start: solid 1px #e3e3e6;
                                                                            margin-inline-start: -1px;
                                                                        }
                                                                    }
                                                                    > .formio-component {
                                                                        &.formio-component-htmlelement {
                                                                            > p {
                                                                                margin-bottom: 0;
                                                                            }
                                                                        }
                                                                        &:where(.formio-component-textfield, .formio-component-number) {
                                                                            input {
                                                                                &.form-control {
                                                                                    background-color: transparent !important;
                                                                                    border: none;
                                                                                    padding: 0;
                                                                                    cursor: default;
                                                                                    height: auto;
            
                                                                                    &:hover {
                                                                                        box-shadow: none;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        &.formio-component-select {
                                                                            .form-control.dropdown {
                                                                                background: transparent;
                                                                                border: none;
                                                                                padding: 0;
                                                                                display: flex;
                                                                                align-items: center;
                                                                                height: auto;
                                                                                min-height: auto;
                                                                                line-height: 1;
            
                                                                                .choices__item {
                                                                                    cursor: default;
                                                                                }
                                                                            }
                                                                        }
                                                                        &.formio-component-datetime {
                                                                            .input-group {
                                                                                input {
                                                                                    &.input {
                                                                                        &.form-control {
                                                                                            border: none;
                                                                                            background: transparent !important;
                                                                                            padding: 0;
                                                                                            cursor: default;
                                                                                            height: auto;
            
                                                                                            &:hover {
                                                                                                box-shadow: none;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                                .input-group-append {
                                                                                    display: none;
                                                                                }
                                                                            }
                                                                        }
                                                                        &.formio-component-textarea {
                                                                            .card-body {
                                                                                padding: 0;
                                                                                
                                                                                .formio-editor-read-only-content {
                                                                                    font-weight: 500;
                                                                                }
                                                                            }
                                                                        }
                                                                        &.formio-component-radio {
                                                                            > .form-radio {
                                                                                margin-top: 0;
                                    
                                                                                .form-check {
                                                                                    display: none;
                                    
                                                                                    &.radio-selected {
                                                                                        display: block;
                                                                                        margin: 0;
                                    
                                                                                        label {
                                                                                            &.form-check-label {
                                                                                                padding: 0;
                                    
                                                                                                input {
                                                                                                    display: none;
                                                                                                }
                                                                                                span {
                                                                                                    font-weight: 500;
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        &.formio-component-datagrid {
                                                                            .datagrid-table {
                                                                                margin-top: 6px;
                                                                                margin-bottom: 6px;
            
                                                                                > tbody {
                                                                                    > tr {
                                                                                        > td {
                                                                                            padding: 0;
            
                                                                                            .formio-component-table {
                                                                                                margin: 0;
                                                                                                padding: 0;
            
                                                                                                table {
                                                                                                    &.table {
                                                                                                        background-color: transparent;
            
                                                                                                        tbody {
                                                                                                            tr {
                                                                                                                &.row {
                                                                                                                    align-items: center;
                                                                                                                    margin: 0;

                                                                                                                    td {
                                                                                                                        min-height: 38px;
                                                                                                                        display: flex;
                                                                                                                        align-items: center;
                                                                                                                        border: none !important;
                                                                                                                        border-inline-end: solid 1px #e3e3e6 !important;
                                                                                                                        border-bottom: solid 1px #e3e3e6 !important;
                                            
                                                                                                                        &:first-child {
                                                                                                                            flex: 1;
                                                                
                                                                                                                            & + td {
                                                                                                                                flex: 3;
                                                                                                                            }
                                                                                                                        }
                                                                                                                        input {
                                                                                                                            &:hover {
                                                                                                                                outline: none;
                                                                                                                            }
                                                                                                                        }
                                                                                                                        .formio-component {
                                                                                                                            margin-top: 0;
                                                                                                                        }
                                                                                                                        &:last-child {
                                                                                                                            border-inline-end: none !important;
                                                                                                                        }                
                                                                                                                        .formio-component {
                                                                                                                            margin-top: 0;

                                                                                                                            &.formio-component-htmlelement {
                                                                                                                                > p {
                                                                                                                                    margin-bottom: 0;
                                                                                                                                }
                                                                                                                            }
                                                                                                                            &:where(.formio-component-textfield, .formio-component-number) {
                                                                                                                                input {
                                                                                                                                    &.form-control {
                                                                                                                                        background-color: transparent !important;
                                                                                                                                        border: none;
                                                                                                                                        padding: 0;
                                                                                                                                        cursor: default;
                                                                                                                                        height: auto;
                
                                                                                                                                        &:hover {
                                                                                                                                            box-shadow: none;
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                            &.formio-component-select {
                                                                                                                                .form-control.dropdown {
                                                                                                                                    background: transparent;
                                                                                                                                    border: none;
                                                                                                                                    padding: 0;
                                                                                                                                    display: flex;
                                                                                                                                    align-items: center;
                                                                                                                                    height: auto;
                                                                                                                                    min-height: auto;
                                                                                                                                    line-height: 1;
                
                                                                                                                                    .choices__item {
                                                                                                                                        cursor: default;
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                            &.formio-component-datetime {
                                                                                                                                .input-group {
                                                                                                                                    input {
                                                                                                                                        &.input {
                                                                                                                                            &.form-control {
                                                                                                                                                border: none;
                                                                                                                                                background: transparent !important;
                                                                                                                                                padding: 0;
                                                                                                                                                cursor: default;
                                                                                                                                                height: auto;
                
                                                                                                                                                &:hover {
                                                                                                                                                    box-shadow: none;
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                    .input-group-append {
                                                                                                                                        display: none;
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                    &:nth-child(odd) {
                                                                                                                        td {
                                                                                                                            background-color: rgb(242, 242, 242);
                                                                                                                        }
                                                                                                                    }
                                                                                                                    &:nth-child(even) {
                                                                                                                        td {
                                                                                                                            background-color: #f5f6f8;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        &:last-child {
                                                                                            > td {            
                                                                                                .formio-component-table {
                                                                                                    > table {
                                                                                                        &.table {
                                                                                                            > tbody {
                                                                                                                > tr {
                                                                                                                    &.row {
                                                                                                                        &:last-child {
                                                                                                                            > td {
                                                                                                                                border-bottom: none !important;
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }  
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    &.formio-errors {
                                                                        display: none;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    &::after {
                                                        display: block;
                                                        position: absolute;
                                                        content: "";
                                                        height: 3rem;
                                                        background: white;
                                                        width: 100%;
                                                        z-index: -9;
                                                        box-shadow: -10px 0 white, 10px 0 white, inset 1px 0 white, inset -1px 0 white, inset 0 1px 2px rgba(0, 0, 0, 0.1);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Default intuitive rules (new formio) */
.formio-form {
    .formio-component {
        &.formio-component-panel {
            > .card {
                > .card-body {
                    > .formio-component {
                        &:not(.formio-component-hidden, .ng-hide) {
                            &:first-of-type {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
        &.formio-component-mycontent {
            mycontent {
                *:last-child {
                    margin-bottom: 0;
                }
                ol {
                    li {
                        margin-bottom: 1rem;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        &.formio-component-table + .formio-component-panel {
            margin-top: 3rem;
        }
        &.formio-component-htmlelement {
            p {
                &:empty {
                    &:first-child {
                        & + p {
                            margin-top: 0;
                        }                        
                        & + ul {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        &.required {
            > label {
                &.field-required {
                    > i {
                        &.fa-question-circle {
                            margin-inline-end: -28px;
                            margin-inline-start: 14px;
                        }
                    }
                }
            }
        }
    }
}
/* Temporary overrides (new formio) */
.formio-form {
    .formio-component {
        &.formio-component-panel {
            &:where(.formio-component-initialquestionspanel, .formio-component-registrationspanel) {
                > .card {
                    border-inline-start: none;

                    > .card-body {
                        padding-bottom: 0;
                        margin-bottom: 0 !important;

                        .card {
                            border: none;
                            padding: 0;
                        }
                    }
                }
            }
            &.formio-component-initialquestionspanel {
                > .card {
                    > .card-body {
                        > .formio-component {
                            &:first-of-type {
                                &.formio-hidden {
                                    & + .formio-component:not(.formio-hidden) {
                                        margin-top: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.formio-component-applicantFees {
                > .card {
                    border-inline-start: none !important;

                    .card-heading {
                        padding: 0;
                    }
                    .card-body {
                        padding: 0;

                        .formio-component-totalPrice {
                            margin: 0;
                        }
                        .formio-component-totalPriceLabelapplicanttotalPricetotalCost {
                            line-height: 1;
                        }
                        h4 {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        &.formio-component-columns {
            &.formio-component-questionscolumns {
                > [class^="col-"] {
                    .formio-component-htmlelement + .formio-component-columns {
                        label.col-form-label:first-of-type {
                            margin-top: 0;
                        }
                    }
                    .formio-component-checkbox > div > label {
                        margin-top: 0;
                    }
                    .formio-component-initialquestionspanel {
                        > .card {
                            > .card-body {
                                margin-bottom: 3rem !important;

                                > .formio-component-columns {
                                    margin: 0;
                                }
                                > .formio-component-htmlelement {
                                    > p {
                                        padding: 0;
                                    }
                                }
                            }
                        }
                        .formio-component {
                            &.formio-component-htmlelement + .formio-component-columns {
                                label.col-form-label {
                                    margin-top: 0;
                                }
                                & ~ .form-group {
                                    .radio-inline {
                                        label.control-label {
                                            margin-top: 0;
                                        }
                                    }
                                }
                                & ~ .formio-component-columns {
                                    .formio-component-mytooltip {
                                        margin-top: 2.75rem;
        
                                        a.btn-icon-toggle {
                                            top: auto !important;
                                        }
                                    }
                                    .formio-component-mytooltip {
                                        .btn-icon-toggle {
                                            border: none;

                                            i {
                                                font-family: var(--fontawesome-font);
                                                font-weight: 900;
                                                -moz-osx-font-smoothing: grayscale;
                                                -webkit-font-smoothing: antialiased;
                                                display: inline-block;
                                                font-style: normal;
                                                font-variant: normal;
                                                text-rendering: auto;
                                                line-height: 1;
                                                font-size: 14px;

                                                &::before {
                                                    content: "\F05A";
                                                }
                                            }
                                            &:hover {
                                                background-color: transparent;
                                            }
                                        }
                                    }
                                }
                            }
                            &.formio-component-htmlelement {
                                margin-top: 0;

                                &.formio-component-guideInitialDescription {
                                    p {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                            &.formio-component-select {
                                .ui-select-choices {
                                    max-height: 14.5rem;
                                }
                            }
                        }
                    }
                    .formio-component-registrationspanel {
                        > .card {
                            > .card-body {
                                margin-bottom: 3rem !important;

                                > .formio-component-panel {
                                    margin-top: 0 !important;
    
                                    > .card {
                                        border-inline-start: none;

                                        > .card-body {
                                            > .formio-component-htmlelement {
                                                margin-bottom: 3rem;
                                                margin-top: 0;
                                                line-height: 1;

                                                > .card-title {
                                                    color: @darkTextColor;
                                                    font-size: 2rem;
                                                    font-weight: 500;
                                                    border: none;
                                                    position: relative;
                                                    padding: 0 0 1rem;
                                                }
                                                p {
                                                    padding: 0;
                                                }
                                            }
                                            .formio-component-checkbox {
                                                margin-top: 1rem;
                                            }
                                        }
                                    }
                                    &.formio-component-columnsPanel2Mandatory {
                                        margin-bottom: 0;

                                        > .card {
                                            > .card-body {
                                                padding: 0;

                                                > .formio-component {
                                                    &:last-of-type {
                                                        &:not(.formio-hidden) {
                                                            &:not(.formio-component-hidden) {
                                                                margin-bottom: 3rem;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .formio-component-requirementsContainer {
                        .formio-component-requirementsRows {
                            margin-top: 0;

                            .form-group {
                                &.form-field-type-htmlelement {
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                    &.col-md-1 {
                        align-items: center;
                        display: flex;
                        justify-content: center;

                        > .formio-component-htmlelement {
                            margin: 0;

                            > p {
                                margin: 0;

                                > span {
                                    font-size: 5rem;
                                    color: #d4d8de;
                                }
                            }
                        }
                    }
                }
            }
            .formio-component-collectionDateLabel {
                text-align: end;
                
                > div {
                    height: 36px;
                    line-height: 36px;
                }
            }
            &.default-pay-form-payments {
                margin-top: 0;
                
                .formio-component-checkbox {
                    &.radio-info {
                        margin-top: 0;
                    }
                }
                &:first-of-type {
                    padding-bottom: 10px;
                }
            }
        }
    }
}
a {
    &[href*="null"] {
        display: none;
    }
}

#formio-form-wizard {
    .formio-form {
        > div {
            .pagination {
                margin: 0 0 3rem;
                display: flex;
                background: var(--wizard-bar-bg-color);
                box-shadow: -1000px 0 0 var(--wizard-bar-bg-color), 1000px 0 0 var(--wizard-bar-bg-color);
                border-radius: 0;
                gap: var(--wizard-bar-items-gap, 0px);

                li {
                    display: flex;
                    flex: 1;
                    align-items: center;
                    justify-content: center;
                    white-space: nowrap;
                    overflow: hidden;

                    .page-link {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        border: none;
                        display: block;
                        width: 100%;
                        text-align: center;
                        transition: all 0.3s;
                        cursor: pointer;
                        margin-inline: 0;

                        &::after {
                            content: "";
                            display: block;
                            width: 20px;
                            height: 20px;
                            border: solid 1px rgba(64, 68, 81, 0.32);
                            border-inline-start: none;
                            border-bottom: none;
                            position: absolute;
                            inset-inline-end: 4px;
                            top: calc(50% - 10px);
                            transform: rotate(45deg);
                        }
                    }
                    &:last-child {
                        .page-link {
                            &::after {
                                display: none;
                            }
                        }
                    }
                    &.page-item {
                        position: relative;
                        
                        .progress-bar {
                            display: block;
                            position: absolute;
                            width: 0%;
                            height: 5px;
                            background: #80c49b;
                            bottom: 0px;
                            z-index: 99;
                            inset-inline-start: 0;
                            transition-duration: 0.2s;
                            border: none;
                            padding: 0;
                        }
                    }
                }
            }
            .list-inline {
                display: none;
            }
            :where(.wizard-page .guide-form, .guide-form) {
                > div[class^="col-"] {
                    display: flex;
                    flex-direction: column;

                    &.col-md-3 {
                        width: 35%;
                        gap: 30px;

                        .formio-component-panel + .formio-component-panel {
                            margin-top: 0px !important;
                        }
                    }
                    &.col-md-4 {
                        align-self: flex-start !important;
                        width: 35%;
                        padding-inline-start: 60px;

                        &::before {
                            content: "22";
                            display: flex;
                            background: transparent;
                            position: absolute;
                            inset-inline-start: 0;
                            width: 46px;
                            height: 100%;
                            align-items: center;
                            justify-content: center;
                            font-family: var(--fontawesome-font);
                            font-weight: 300;
                            -moz-osx-font-smoothing: grayscale;
                            -webkit-font-smoothing: antialiased;
                            font-style: normal;
                            font-variant: normal;
                            text-rendering: auto;
                            line-height: 1;
                            content: "\F105";
                            font-size: 50px;
                            color: #d4d8de;
                        }
                        > .formio-component-panel {
                            .card {
                                .card-body {
                                    .formio-component-checkbox {
                                        & ~ .formio-component-checkbox {
                                            .form-check {
                                                margin-top: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .formio-component-FeesPanel {
                    margin-bottom: 0;
                    height: auto;

                    .card {
                        .card-body {
                            .formio-component-container {
                                border-top: solid 1px #93cdaa;
                                margin-top: -1px !important;
                                padding-top: 10px;
                            }
                            :where(.formio-component-container, .formio-component-columns) {
                                margin-top: 0;

                                .formio-component-htmlelement {
                                    margin-top: 0;
                                    color: white;
                                    display: flex;
                                    align-items: center;
                                    padding: 6px 0;

                                    p {
                                        color: white;
                                        font-weight: 500;
                                        margin: 0;
                                        display: block;
                                        width: 100%;

                                        span {
                                            font-size: 15px;
                                            margin-inline-start: auto;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .formio-component-requiredDocumentsPanel {
                    height: auto;

                    > .card {
                        background-color: white;
                        
                        > .card-header {
                            margin-bottom: 0 !important;
                            width: 100%;

                            .card-title {
                                i {
                                    margin-inline-end: 0px;
                                }
                            }
                        }
                        > .card-body {
                            > .formio-component {
                                &.formio-component-htmlelement {
                                    margin: 0;

                                    > p {
                                        width: 100%;
                                        border-radius: 0;
                                        margin: 0!important;
                                        background: transparent;
                                        color: #404451;
                                        font-size: 14px;
                                        font-weight: 500;
                                        border: none;
                                        position: relative;
                                        line-height: 1.6;
                                        text-align: start;
                                        text-transform: capitalize;
                                        letter-spacing: -.2pt;
                                    }
                                    & + .formio-component-container {
                                        margin-top: 1rem;
                                    }
                                }
                                &.formio-component-container {
                                    &:first-of-type {
                                        margin-top: 0;
                                        border-top: .1rem solid #dddddd;
                                    }
                                    > div {
                                        > .formio-component {
                                            margin-top: 0;

                                            &.formio-component-htmlelement {
                                                li {
                                                    list-style: none;
                                                    display: block;
                                                    line-height: 2.3;

                                                    > div {
                                                        display: flex;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    & + .formio-component-htmlelement {
                                        margin-top: 1rem;
                                    }
                                }
                            }
                        }
                    }
                    .requirement-name {
                        margin-inline-end: auto;
                    }
                }
                .formio-component-submitButton {
                    margin-top: 1.5rem;
                }
                .formio-component {
                    &.formio-component-panel {
                        > .card {
                            > .card-body {
                                > .formio-component {
                                    &:not(.formio-component-hidden, .ng-hide) {
                                        &:first-of-type {
                                            margin-top: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
#formio-form-role,
#formio-form-wizard {
    > .alert {
        &.alert-danger {
            margin: 0 !important;
            padding: 24px !important;
            background: white !important;
            border: solid 1px @errorColor !important;
            position: fixed !important;
            z-index: 99;
            bottom: 12px;
            width: 90%;
            max-width: 600px;
            inset-inline-end: 12px;
            box-shadow: 0 5px 20px rgba(0,0,0,0.2);

            &::after {
                display: none !important;
            }
            p {
               font-size: 15px;
               font-weight: 500;
               color: @errorColor;
            }
            ul {
                li {
                    &:hover {
                        background: #f3f0f0;
                        padding-inline-start: 8px;
                        transition-duration: 0.2s;

                        span {
                            &::after {
                                content: "»";
                                color: #999;
                                margin-inline-start: 12px;
                            }
                        }
                    }
                    &:focus {
                        outline: none;
                        background-color: #e1e4ea;
                    }
                }
            }
        }
    }
}

// Page wrapper with options
.contain-lg {
    max-width: var(--page-wrapper-max-width, 1200px);
}
#header .contain-lg {
    --page-wrapper-max-width: var(--page-wrapper-header-width);
}
.user-steps-menu,
.modern-theme-dashboard-menu,
.secondary-menu,
.demo-page-menu,
.demo-mode-heading,
.modern-theme-be-list {
    .contain-lg {
        --page-wrapper-max-width: var(--page-wrapper-menu-width);
    }
}
.several-messages-container {
    --page-wrapper-max-width: var(--page-wrapper-menu-width);
}
.demo-mode-heading .contain-lg {
    --page-wrapper-max-width: calc(95% + 40px);
}
.section-body .contain-lg {
    --page-wrapper-max-width: var(--page-wrapper-content-width, 1200px);
}
.management_bar,
.management_bar + #base {
    .contain-lg {
        --page-wrapper-max-width: var(--page-wrapper-form-width);
    }
}
#content .full-bleed .contain-lg {
    --page-wrapper-max-width: 100%;
}
.wizard-page {
    max-width: var(--page-wrapper-wizard-width);
    margin-inline: auto;
}
.part-b-main-content-row,
.part-b-content {
    max-width: var(--page-wrapper-form-width);
    margin-inline: auto;
}
.part-b-main-content-row {
    .col-lg-12 {
        padding-inline: 0;
    }
}
#base > #content {
    padding-inline: 2.4rem;
}

#g2c-payment-iframe {
    width: 100%;
    height: 640px;
    margin-top: 30px;
    border: none;
    border-top: 60px solid white;
    border-radius: 6px;
}